import React from 'react'

export default function Client() {
    return (
        <section className='client_section p-l-r-1 -bg--bg-color2'>
            <div className='grid grid-cols-5 gap-4 max-[991px]:grid-cols-4 max-[768px]:grid-cols-3 max-[576px]:grid-cols-3'>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/1.png')} alt='1' />
                    </div>
                    {/* <h6>SiliconIndia</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/2.png')} alt='2' />
                    </div>
                    {/* <h6>Entrepreneur</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/3.png')} alt='3' />
                    </div>
                    {/* <h6>Asia Business<br /> Outlook</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/4.png')} alt='4' />
                    </div>
                    {/* <h6>Global Marketing<br />
                        Excellence Awards 2023</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/5.png')} alt='5' />
                    </div>
                    {/* <h6>Global Marketing<br />
                        Excellence Awards 2023</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/6.png')} alt='6' />
                    </div>
                    {/* <h6>Asia Business<br />
                        Outlook 2023</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/7.png')} alt='7' />
                    </div>
                    {/* <h6>Indian Achiever’s<br />
                        Forum</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/8.png')} alt='8' />
                    </div>
                    {/* <h6>Stars Of Industry Awards</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                        <img src={require('../../assets/images/award/9.png')} alt='9' />
                    </div>
                    {/* <h6>Stars Of Industry
                        Awards</h6> */}
                </div>
                <div className='-bg--bg-color2'>
                    <div className='client_logo'>
                    <img src={require('../../assets/images/award/10.png')} alt='10' />
                    </div>
                    {/* <h6>Forbes India</h6> */}
                </div>

            </div>
        </section>
    )
}

import React, { useEffect } from 'react'
import Client from '../client/Client'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
export default function ClientsPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })



    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>Clients</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        Delivering tailored solutions<br /> that drive real results
                    </h2>
                </div>
            </section>
            <section className='client_logo_section p-l-r-1 top bottom -bg--text-color1'>
                <div className='client-logo-section -bg--bg-color'>
                    <ul className='grid grid-cols-6 gap-10 grid-flow-row '>
                        <li>
                            <img src={require('../../assets/images/clients/client-1.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-2.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-3.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-4.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-5.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-6.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-7.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-8.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-9.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-10.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-11.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-12.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-13.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-14.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-15.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-16.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-17.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-18.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-19.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-20.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-21.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-22.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-23.jpg')} />
                        </li>
                        <li>
                            <img src={require('../../assets/images/clients/client-24.jpg')} />
                        </li>
                    </ul>
                </div>
            </section>
            <section className='-bg--bg-color1 client_section top_1 bottom_1 p-l-r-1'>
                <div className='header-title'>
                    <h3 className='heading-1 uppercase text-center'>
                        Stories That Speak<br /> for Themselves
                    </h3>
                </div>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of 'Ek Khat'. The idea was to get the customer insights personally. 'Ek Khat' campaign helped us get registered in the minds of farmers as a brand which gives due importance to their advices and issues. The response was overwhelming with the letters, and we will continue to build such campaigns that spark a personal connect with our customers."
                                </p>
                                <h4>ONKAR NATH</h4>
                                <h5>Marketing Manager, Mosaic India Pvt Ltd.</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "There are many things that can set Brand street apart - cost effectiveness, innovativeness of ideas, executional excellence to name a few- but I would recommend them foremost for superior client service. They are very prompt, efficient and always meet the committed timelines. They have been very reliable partners for us.
                                    We have delivered some good projects together, and I hope that
                                    we continue doing so."
                                </p>
                                <h4>RICHA OJHA</h4>
                                <h5>Brand Manager, Shell Rimula</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "As a client of Brand Street Integrated, I've witnessed firsthand the power of experiential marketing in driving customer engagement.
                                    Their team's innovative approach and commitment to delivering impactful experiences have exceeded our expectations."
                                </p>
                                <h4>TARUN VERMA</h4>
                                <h5>Director, Delhi</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "Brand Street Integrated has been instrumental in helping us navigate the diverse Indian market. Their ability to innovate and adapt to changing consumer trends has been invaluable to
                                    our brand's growth."
                                </p>
                                <h4>PRIYA SHARMA</h4>
                                <h5>Marketing Manager, Mumbai</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "Brand Street Integrated understands the importance of creating memorable experiences for our target audience. Their dedication to building lasting connections through experiential marketing has been instrumental in our brand's success."

                                </p>
                                <h4>VIKASH SHARMA</h4>
                                <h5>Startup, Faridabad</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='client_box'>
                            <div className='client-details'>

                                <p className='martop'>
                                    "Working with Brand Street Integrated has been a game-changer for our brand. Their expertise in experiential marketing truly sets them apart. The consumer community has flourished under their guidance, and the impact of our brand's differentiation is undeniable."
                                </p>
                                <h4>AKASH BHARDWAJ</h4>
                                <h5>Business Owner, Gurgaon</h5>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </section>
            <Client />
        </>
    )
}

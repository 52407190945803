import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Header() {

  const [toggle, setToggle] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setToggle(false);
  }, [location]);

  return (
    <div>
      <header class="p-l-r relative top-0 left-0 w-full z-10">
        <nav class="bg-white-800 p-3 max-[991px]:px-0">
          <div class="mx-auto">
            <div class="flex items-center justify-between ">
              <div class="flex items-center">
                <div class="flex-shrink-0 ">
                  <Link to='/'>
                    <img class="h-14 max-[1366px]:h-12 max-[576px]:h-10" src={require('../assets/images/BSI-LOGO.png')} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className='menu-section'>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-4">
                    <ul className='flex items-center justify-center'>
                      <li><Link to={"/work"} className='mx-6 text-[16px] hover:-text--text-color2 font-HelveticaNeueMediumExt font-medium text-base'>Work</Link></li>
                      <li><Link to={"/services"} className='mx-6 text-[16px]  hover:-text--text-color2 font-HelveticaNeueMediumExt font-medium text-base'>Services</Link></li>
                      <li><Link to={"/clients"} className='mx-6 text-[16px]  hover:-text--text-color2 font-HelveticaNeueMediumExt font-medium text-base'>Clients</Link></li>
                      <li><Link to={"/about"} className='mx-6 text-[16px]  hover:-text--text-color2 font-HelveticaNeueMediumExt font-medium text-base'>About</Link></li>
                      <li><Link to={"/contact"}><button className='hover-btn-box btn_1 -text--text-color1 -bg--bg-color rounded-full btn--2 text-[16px] font-HelveticaNeueMediumExt'><span class="btn__text">Contact Us</span></button></Link></li>
                    </ul>

                  </div>
                </div>
              </div>

              <div class="flex md:hidden">

                <button type="button" onClick={() => setToggle(true)} class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 p-0" aria-controls="mobile-menu" aria-expanded="false">
                  <span class="absolute -inset-0.5"></span>
                  <span class="sr-only">Open main menu</span>

                  <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>

                  <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>


          <div class={toggle == true ? 'show' : 'hide'} id="mobile-menu">
            <button onClick={() => setToggle(false)} className="absolute top-0 right-0 w-10 h-10 -bg--bg-color1 -text--text-color"><i class="fa-solid fa-xmark"></i></button>
            <div className="logo-mobile">
              <img class="h-14 max-[1366px]:h-13 mb-4" src={require('../assets/images/Logo Only_BSI1 1.png')} alt="logo" />
            </div>
            <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3 text-center">

              <Link to="/work" class="-text--text-color1 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Work</Link>
              <Link to="/services" class="-text--text-color1 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Services</Link>
              <Link to="/clients" class="-text--text-color1 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Clients</Link>
              <Link to="/about" class="-text--text-color1 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">About</Link>
              <Link to="/contact" class="-text--text-color1 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Contact Us</Link>
            </div>
            <div class="border-t border-gray-700 pb-3 pt-4">
              <div class="social-media">

                <ul className='flex'>

                  <li><a className='w-8 -bg--bg-color1 -text--text-color4 p-3 hover:-bg--text-color2 ml-2 h-8  rounded-full flex items-center justify-center' href="https://www.facebook.com/TheBeyondLifestyleApp" target="_blank"><i
                    class="fa-brands fa-facebook-f"></i></a></li>

                  <li><a className='w-8 -bg--bg-color1 -text--text-color4 p-3 hover:-bg--text-color2 ml-2 h-8  rounded-full flex items-center justify-center' href="https://twitter.com/LifestyleApp24" target="_blank"><i
                    class="fa-brands fa-x-twitter"></i></a></li>

                  <li><a className='w-8 -bg--bg-color1 -text--text-color4 p-3 hover:-bg--text-color2 ml-2 h-8  rounded-full flex items-center justify-center' href="https://www.instagram.com/the_beyond_lifestyle/" target="_blank"><i
                    class="fa-brands fa-instagram"></i></a></li>

                  <li><a className='w-8 -bg--bg-color1 -text--text-color4 p-3 hover:-bg--text-color2 ml-2 h-8  rounded-full flex items-center justify-center' href="https://www.youtube.com/@TheBeyondLifestyle" target="_blank"><i
                    class="fa-brands fa-youtube"></i></a></li>

                  <li><a className='w-8 -bg--bg-color1 -text--text-color4 p-3 hover:-bg--text-color2 ml-2 h-8  rounded-full flex items-center justify-center' href="https://www.linkedin.com/company/the-beyond-lifestyle/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>

                </ul>

              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

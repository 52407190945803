import { ReactLenis, useLenis } from '@studio-freight/react-lenis';
import Router from './route/Router';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <ReactLenis
      root
      options={{ gestureOrientataion: "both" }}
    >
      <ParallaxProvider>
        <div className="App">
          <Router />
          <ToastContainer />
        </div>
      </ParallaxProvider>
    </ReactLenis>
  );
}

export default App;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/home/Home';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Service from '../pages/Service/Service';
import Work from '../pages/work/Work';
import ClientsPage from '../pages/client/ClientsPage';
import About from '../pages/about/About';
import Contact from '../pages/Contact/Contact';
import NewsUpdate from '../pages/NewsUpdate/NewsUpdate';
import Work2 from '../pages/work/Work2';
import PrivacyPolicy from '../pages/privacypolicy/PrivacyPolicy';

export default function Router() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path='/services' element={<Service />} />
                <Route path='/work' element={<Work />} />
                <Route path='/work-2' element={<Work2 />} />
                <Route path='/clients' element={<ClientsPage />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/news-and-update' element={<NewsUpdate />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                {/* <Route path='/*' element={<NotFound />} /> */}
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import Client from '../client/Client'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        console.log('v', form.current)
        emailjs
            .sendForm('service_68ur0nw', 'template_mqi1vmv', form.current, {
                publicKey: 'l5EdrusuTXjgGXoWw',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    toast("SUCCESS!");
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })


    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>Contact</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        GET IN<br /> TOUCH
                    </h2>
                </div>
            </section>

            <section className='-bg--bg-color1 contact-section top_1 bottom_1 p-l-r-1'>
                <div className='w-full flex max-[991px]:flex-wrap'>
                    <div className='left-section w-1/2 max-[991px]:w-full'>

                        <div class="contact-detail">

                            <div class="call-to-action">
                                <a href="tel:18008908766" class="wow fadeInUp" ><i class="fa-solid fa-phone"></i> 1800-890-8766</a>
                                <a href="mailto:surendra@thebrandstreet.co" class="wow fadeInUp" ><i class="fa-solid fa-envelope"></i> surendra@thebrandstreet.co</a>
                            </div>
                            <div class="social_media">
                                <p className='mb-5 font-HelveticaNeueMediumExt -text--text-color text-[20px]'>Follow us:</p>
                                <ul>
                                    <li class="" >
                                        <a href="https://www.facebook.com/brandstreetintegrated/" target="_blank" class="fb"><i class="fa-brands fa-facebook"></i></a>
                                    </li>
                                    <li class="">
                                        <a href="https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D" target="_blank" class="insta"><i class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li class="">
                                        <a href="https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in" target="_blank" class="twitter"><i class="fa-brands fa-linkedin-in"></i></a>
                                    </li>
                                    <li class="">
                                        <a href="https://twitter.com/BrandStreetInt1" target="_blank" class="twitter"><i class="fa-brands fa-x-twitter"></i></a>
                                    </li>
                                    <li class="">
                                        <a href="https://www.youtube.com/channel/UCs9vDqDgv7CzMkZa6mJ1ZxA" target="_blank" class="twitter"><i class="fa-brands fa-youtube"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className='right-section w-1/2 max-[991px]:w-full max-[991px]:mt-8'>
                        <div className='contact-form'>
                            <form
                                ref={form}
                                onSubmit={sendEmail}
                            >
                                <div className='input-field'>
                                    <input name="name" placeholder="Name" required />
                                </div>
                                <div className='input-field'>
                                    <input name="email" placeholder="Email" required />
                                </div>
                                <div className='input-field'>
                                    <input name="phone" placeholder="Phone No." required />
                                </div>
                                <div className='input-field'>
                                    <input name="company" placeholder="Company" required />
                                </div>
                                <div className='input-field'>
                                    <input name="jobtitle" placeholder="Job title" required />
                                </div>
                                <div className='input-field'>
                                    <select type="select" name="interested">
                                        <option value={""}>Which Service are you interested in?</option>
                                        <option value={"Consumer Marketing"}>Consumer Marketing</option>
                                        <option value={"Trade Marketing"}>Trade Marketing</option>
                                        <option value={"Rural Marketing"}>Rural Marketing</option>
                                        <option value={"Digital Marketing"}>Digital Marketing</option>
                                        <option value={"OOH"}>OOH</option>
                                        <option value={"Creative Services"}>Creative Services</option>
                                        <option value={"Merchandising"}>Merchandising</option>
                                        <option value={"Printing and POSM"}>Printing and POSM</option>
                                    </select>
                                </div>
                                <div className='input-field'>
                                    <input name="like" placeholder="Anything else you’d like to add?" required />
                                </div>
                                <div className='input-field'>
                                    <button type='submit' className='btn--2 btn_4'><span class="btn__text">Send</span></button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </section>

            <Client />
        </>
    )
}

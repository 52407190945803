import React, { useEffect } from 'react'
import Client from '../client/Client'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
export default function NewsUpdate() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })

    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>News & Update</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        LATEST NEWS
                    </h2>
                </div>
            </section>
            <section className='-bg--bg-color1 work_list_section top_1 bottom_1 p-l-r-1'>
                <div class="grid grid-cols-2 max-[576px]:grid-cols-1  gap-20 max-[1199px]:gap-10 work-list">
                    <a href='https://entrepreneuronemedia.com/interviews/brand-street-integrated-pioneering-experiential-marketing-and-innovation/' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/Surendra-Singh_Entrepreneur-First-Media-750x422.png')} alt='Surendra-Singh_Entrepreneur-First-Media-750x422.png' />
                            </div>
                            <h5>Brand Street Integrated: Pioneering Experiential Marketing and Innovation</h5>
                            <p className='paragraph'>With the increase in digital wave and information availability, the call for a broad presence was made...</p>
                        </div>
                    </a>
                    <a href='https://www.adgully.com/brand-street-india-records-a-surge-of-60-with-four-movie-brand-integration-72911.html' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/star-cast-photo-with-contest-winners-brand-street-india.jpg')} alt='star-cast-photo-with-contest-winners-brand-street-india.jpg' />
                            </div>
                            <h5>Brand Street India records a surge of 60% with four movie-brand integration</h5>
                            <p className='paragraph'>Brand Street India, leading integrated marketing agency, recorded a remarkable growth of 60% in its business within a...</p>
                        </div>
                    </a>
                    <a href='https://www.medianews4u.com/brand-street-integrated-executes-new-campaign-store-on-wheels-in-association-with-20-brands/' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/Brand-Street-Integrated-executes-new-campaign-Store-On-wheels-in-association-with-20-brands.jpg')} alt='star-cast-photo-with-contest-winners-brand-street-india.jpg' />
                            </div>
                            <h5>Brand Street Integrated executes new campaign “Store On wheels” in association with 20+ brands</h5>
                            <p className='paragraph'>New Delhi: Brand Street Integrated, India’s leading experiential marketing agency, recently executed an out of the box concept, low on budget...</p>
                        </div>
                    </a>
                    <a href='https://www.exchange4media.com/experiential-marketing-news/brand-street-integrated-builds-on-d2c-model-announces-partnership-with-leading-brands-106792.html' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/106792-brandstreet.webp')} alt='106792-brandstreet' />
                            </div>
                            <h5>Brand Street Integrated builds on D2C model, announces partnership with leading brands</h5>
                            <p className='paragraph'>In the wake of the nationwide success of their ‘Store on Wheels’ campaign, experiential marketing agency Brand Street Integrated (BSI)...</p>
                        </div>
                    </a>
                    <a href='https://www.media4growth.com/ooh-news/brand-street-integrated-bridges-gap-between-consumers-and-brands-with-store-on-wheels-initiative-5137' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/1596169765.jpg')} alt='106792-brandstreet' />
                            </div>
                            <h5>Brand Street Integrated bridges gap between consumers and brands with “Store on Wheels” initiative</h5>
                            <p className='paragraph'>In order to bridge the gap between consumers and brands, marketing agency Brand Street Integrated (BSI) has expanded its new campaign “Store on Wheels”...</p>
                        </div>
                    </a>
                    <a href='https://brandequity.economictimes.indiatimes.com/' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/dfgh.jpg')} alt='106792-brandstreet' />
                            </div>
                            <h5>Brand Street India's new division Retail Story releases its first promotional companies</h5>
                            <p className='paragraph'>Brand Street India, a leading integrated marketing agency recently announced the ...</p>
                        </div>
                    </a>
                    <a href='https://www.retail4growth.com/viewpoints/only-tech-disruption-will-make-shopping-experience-wow-821' target='_blank'>
                        <div className='work-card'>
                            <div className='img-card'>
                                <img src={require('../../assets/images/news/1599197036.jpg')} alt='106792-brandstreet' />
                            </div>
                            <h5>‘Only tech disruption will make shopping experience WOW’</h5>
                            <p className='paragraph'>Experiential marketing agency Brand Street Integrated recently executed a low budget campaign called the ‘Store on Wheels’ ...</p>
                        </div>
                    </a>
                </div>
            </section>
            <Client />
        </>
    )
}

import React, { useEffect } from 'react'
import Client from '../client/Client'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link, useNavigate } from 'react-router-dom';
export default function Work2() {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })

    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>Work</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        Where Vision <br />Meets Execution
                    </h2>
                </div>
            </section>
            <section className='-bg--bg-color1 work_list_section top_1 bottom_1 p-l-r-1'>
                <div class="grid grid-cols-2 max-[576px]:grid-cols-1  gap-20 max-[1199px]:gap-10 work-list">
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/projects/BCL.jpg')} alt='BCL' />
                        </div>
                        <h4>Home Cook</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/projects/Shell.jpg')} alt='Shell' />
                        </div>
                        <h4>Shell</h4>
                    </div>
                   
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/projects/TGP.jpg')} alt='TGP' />
                        </div>
                        <h4>TATA</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/projects/Ujala.jpg')} alt='Ujala' />
                        </div>
                        <h4>Ujala</h4>
                    </div>
                   
                </div>
                <div className='pagination-box top_1'>
                    <ul className='flex items-center'>
                        <li><button className=' -text--text-color3' onClick={()=>navigate('/work')}>1</button></li>
                        <li><button className='active -text--text-color3'>2</button></li>
                    </ul>
                </div>
            </section>
            <Client />
        </>
    )
}

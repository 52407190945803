import React, { useEffect } from 'react'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';

export default function Service() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })



    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>Services</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        Your Gateway to Tailored<br /> Success Strategies
                    </h2>
                </div>
            </section>
            <section className='-bg--bg-color1 top_1 bottom_1 p-l-r-1'>
                <div className='row_list flex items-center'>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Mask-group.png')} alt='Mask-group' />
                        </div>
                    </div>
                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Consumer Marketing</h3>
                            <p className='paragraph'>Cultivating enduring brand affinity through strategic consumer engagement</p>
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>
                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Trade Marketing</h3>
                            <p className='paragraph'>Orchestrating market dominance, influencing trade dynamics with precision.</p>
                        </div>
                    </div>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Trade-Marketing.png')} alt='Trade-Marketing' />
                        </div>
                    </div>

                </div>
                <div className='row_list flex items-center'>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Rural-Marketing.png')} alt='Rural-Marketing.png' />
                        </div>
                    </div>
                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Rural Marketing</h3>
                            <p className='paragraph'>Delving into rural landscapes, crafting resonant campaigns for heartland markets.</p>
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>

                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Digital Marketing</h3>
                            <p className='paragraph'>Navigating the digital realm with strategic process, maximizing brand visibility and engagement.</p>
                            <a href='https://3percent.in/' target='_blank' className='button_animation'>
                                <img src={require('../../assets/images/long_right.png')} className='ml-2 w-5 mt-1 left_icon' />
                                Visit Website
                                <img src={require('../../assets/images/long_right.png')} className='ml-2 w-5 mt-1 right_icon' />
                            </a>
                        </div>
                    </div>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Digital-Marketing.png')} alt='Digital-Marketing' />
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/OOH.png')} alt='OOH' />
                        </div>
                    </div>
                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>OOH</h3>
                            <p className='paragraph'>Commanding attention with impactful outdoor advertising strategies.</p>
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>

                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Creative Services</h3>
                            <p className='paragraph'>Architecting brand narratives with meticulous creativity and finesse.</p>
                        </div>
                    </div>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Creative-Services.png')} alt='Creative-Services' />
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Merchandising.png')} alt='Merchandising' />
                        </div>
                    </div>
                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Merchandising</h3>
                            <p className='paragraph'>Elevating brand presence through meticulously curated merchandising solutions.</p>
                        </div>
                    </div>
                </div>
                <div className='row_list flex items-center'>

                    <div className='right-section w-1/2'>
                        <div className='content-section'>
                            <h3>Printing and POSM</h3>
                            <p className='paragraph'>Delivering immaculate print materials and POSM solutions with unwavering quality.</p>
                        </div>
                    </div>
                    <div className='left-section w-1/2'>
                        <div className='img-box'>
                            <img src={require('../../assets/images/Printing-and-POSM.png')} alt='Printing-and-POSM' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

import React, { useRef, useState } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';


export default function Footer() {

  const [lable, setLabel] = useState(false)

  const form1 = useRef();

  const sendEmail1 = (e) => {
    e.preventDefault();
    console.log('v', form1.current)
    emailjs
      .sendForm('service_68ur0nw', 'template_yl8gae9', form1.current, {
        publicKey: 'l5EdrusuTXjgGXoWw',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast("SUCCESS!");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const onChnageFunction = () => {
    setLabel(true)
  }

  return (
    <>
      <div className='location-list'>
        <ul>
          <li>Delhi</li>
          <li>Mumbai</li>
          <li>Kolkata</li>
          <li>Chennai</li>
          <li>Bengaluru</li>
          <li>Coimbatore</li>
          <li>Hyderabad</li>
          <li>Ahmedabad</li>
          <li>Pune</li>
        </ul>
      </div>

      <footer className='footer-section p-l-r-1 top'>

        <div className='footer-col flex max-[991px]:flex-wrap'>
          <div className='w-1/2 max-[991px]:w-full'>
            <div className='quick-form'>
              <h5 className='text-[20px] -text--text-color1 font-HelveticaNeueMediumExt'><span className='-text--text-color2'>Subscribe</span> for our newsletter</h5>
              <form
                ref={form1}
                onSubmit={sendEmail1}
              >
                <div className='input_field'>
                  <label className={lable ? 'active' : ''}>Email <img src={require('../assets/images/long_right_1.png')} /></label>
                  <input name="email" type="email" onFocus={onChnageFunction} />
                </div>
              </form>
            </div>
            <div className='footer-left-section'>
              <div className='footer-logo'>
                <img src={require('../assets/images/Logo Only_BSI1 1.png')} alt='Logo Only_BSI1 1' />
              </div>
              <p className='paragraph'>ENKAY TOWER, Tower A, First Floor, Udyog Vihar,<br /> Phase V, Sector 19, Gurugram, Haryana 122022</p>
              <a href='tel:18008908766' className='paragraph py-6 block'>1800-890-8766</a>
              <ul>
                <li><a href='mailto:surendra@thebrandstreet.co' className='paragraph'>surendra@thebrandstreet.co</a></li>
                <li><a href='mailto:surendra@thebrandstreet.co' className='paragraph'>contact@thebrandstreet.co</a></li>
              </ul>
            </div>
          </div>
          <div className='w-1/2 max-[991px]:w-full max-[991px]:mt-10'>
            <div className='footer-right-section relative max-[991px]:pl-0'>
              <ul className='flex items-center justify-between max-[576px]:flex-wrap'>
                <li className='w-fit '><Link to='/work' className='text-[14px] max-[576px]:text-[14px] -text--text-color1 font-HelveticaNeueMediumExt '>Work</Link></li>
                <li className='w-fit '><Link to='/services' className='text-[14px] max-[576px]:text-[14px] -text--text-color1 font-HelveticaNeueMediumExt '>Services</Link></li>
                <li className='w-fit '><Link to='/clients' className='text-[14px] max-[576px]:text-[14px] -text--text-color1 font-HelveticaNeueMediumExt '>Clients</Link></li>
                <li className='w-fit '><Link to='/about' className='text-[14px] max-[576px]:text-[14px] -text--text-color1 font-HelveticaNeueMediumExt '>About</Link></li>
                <li className='w-fit '><Link to='/news-and-update' className='text-[14px] max-[576px]:text-[16px] -text--text-color1 font-HelveticaNeueMediumExt '>News & Update</Link></li>
              </ul>
              <h3 className='heading-1 -text--text-color1 martop pt-5 uppercase'>
                Let's Turn Your<br />
                <span className='-text--text-color2'> Vision into Reality</span>
              </h3>
              <ul className='flex items-center pt-4 pb-8 -text--text-color1'>
                <li>
                  <a href='https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in' target='_blank' className='text-[20px] max-[991px]:text-[16px] max-[576px]:text-[15px] font-HelveticaNeueMediumExt pr-14'>Linkedin</a>
                </li>
                <li>
                  <a href='https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D' target='_blank' className='text-[20px] max-[991px]:text-[16px] max-[576px]:text-[15px] font-HelveticaNeueMediumExt pr-14'>Instagram</a>
                </li>
                <li>
                  <a href='https://x.com/i/flow/login?redirect_after_login=%2FBrandStreetInt1' target='_blank' className='text-[20px] max-[991px]:text-[16px] max-[576px]:text-[15px] font-HelveticaNeueMediumExt pr-14'>X</a>
                </li>
              </ul>
              <Link to='/contact' className='text-[16px] font-bold -bg--bg-color1 px-8 rounded-sm font-HelveticaNeueMediumExt py-2 -text--text-color block w-fit btn--2 btn_3'><span class="btn__text">Contact US</span></Link>
              <img src={require('../assets/images/img.png')} className='banner-logo-2' />
            </div>
          </div>
        </div>
        <div class="footer-copyright row">
          <div class="col_4 w-full">
            <div class="copy-right-p -text--text-color1"><p>Copyright  © 2024 Brand Street Integrated. </p>
            </div>
          </div>
          <div class="col_4 w-full">
            <div class="privacy-policy"><p><Link to="/privacy-policy">Privacy Policy</Link></p>
            </div>
          </div>
          <div class="col_4 w-full">
            <div class="website-link">
              <p>Website by <a href="https://www.rajmith.com/" target="_blank">RAJMITH</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

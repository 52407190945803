import React, { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import Client from '../client/Client';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';

const News = [
  {
    img: require('../../assets/images/img-1.jpg'),
    title: 'Press Release',
    text: 'Brand Street Integrated: Pioneering Experiential Marketing and Innovation'
  },
  {
    img: require('../../assets/images/img-2.jpg'),
    title: 'Press Release',
    text: 'Brand Street Integrated’ acquires digital agency 3% Collective'
  },
  {
    img: require('../../assets/images/img-3.jpg'),
    title: 'Press Release',
    text: 'Brand Street India: "Creating Experiences that Seamlessly Please Every Heart"'
  }

]

export default function Home() {

  const [imgSet, setImgSet] = useState(0)
  const svgRef = useRef(null);
  useEffect(() => {
    const svg = svgRef.current;
    const paths = Array.from(svg.querySelectorAll('path'));

    // Remove paths from the DOM
    paths.forEach(path => path.remove());

    // Shuffle paths
    paths.sort(() => Math.random() - 0.5);

    // Append paths back to the SVG
    paths.forEach(path => svg.appendChild(path));
  }, []);

  useGSAP(() => {
    var el = document.querySelector('.banner_heading');
    var s = new SplitType(
      el, {
      type: "lines, words",
      linesClass: "ts-line"
    }
    );

    const tl = gsap.timeline({ delay: 0.5 });
    tl.addLabel('enter');

    tl.staggerFromTo(
      s.words,
      0.6, {
      yPercent: 100,
    }, {
      yPercent: 0,
      ease: 'Circ.easeOut'
    },
      0.2,
      'enter'
    );

    tl.staggerFromTo(
      s.words,
      0.6, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'Power1.easeOut'
    },
      0.2,
      'enter'
    );
  })


  const handleMouseEnter = (index) => {
    setImgSet(index);
  };

  const handleMouseLeave = () => {
    setImgSet(null);
  };

  return (
    <>
      <section className='banner-section w-full h-screen max-[991px]:h-full'>
        <img src={require('../../assets/images/img.png')} className='banner-logo' />
        <div className='w-full flex items-center h-full max-[991px]:flex-col'>
          <div className='w-1/2 max-[991px]:w-full'>
            <div className='left-section banner_left'>
              <svg ref={svgRef} version="1.1" x="0px" y="0px"
                viewBox="0 0 385 430" style={{ enableBackground: 'new 0 0 385 430' }}>
                <path class="st0" d="M191,158c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,163.2,191,160.8,191,158" />
                <path class="st0" d="M191,145c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,150.2,191,147.9,191,145" />
                <path class="st0" d="M178.7,146.8c-0.4-2.8,1.5-5.5,4.3-6c2.8-0.5,5.5,1.5,6,4.3c0.5,2.8-1.5,5.5-4.3,6
	C181.8,151.6,179.2,149.6,178.7,146.8"/>
                <path class="st0" d="M166.9,150.4c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6
	C170.8,154.7,167.8,153.2,166.9,150.4"/>
                <path class="st1" d="M155.8,155.9c-1.3-2.6-0.3-5.7,2.3-7c2.6-1.3,5.7-0.3,7,2.3c1.3,2.6,0.3,5.7-2.3,7
	C160.3,159.4,157.1,158.4,155.8,155.9"/>
                <path class="st1" d="M145.7,163c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3
	C150.7,165.8,147.4,165.3,145.7,163"/>
                <path class="st1" d="M136.9,171.6c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4C142.2,173.6,138.9,173.6,136.9,171.6" />
                <path class="st1" d="M129.5,181.4c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3
	C135,182.6,131.8,183.1,129.5,181.4"/>
                <path class="st2" d="M123.7,192.3c-2.6-1.3-3.6-4.4-2.3-7c1.3-2.6,4.4-3.6,7-2.3c2.6,1.3,3.6,4.4,2.3,7
	C129.4,192.6,126.2,193.7,123.7,192.3"/>
                <path class="st0" d="M119.7,204c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6
	C125.4,203.4,122.4,204.9,119.7,204"/>
                <path class="st0" d="M117.6,216.2c-2.8-0.4-4.8-3.1-4.3-6c0.4-2.8,3.1-4.8,6-4.3c2.8,0.5,4.8,3.1,4.3,6
	C123.1,214.7,120.4,216.6,117.6,216.2"/>
                <path class="st2" d="M117.4,228.5c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2
	C122.6,226.2,120.2,228.5,117.4,228.5"/>
                <path class="st1" d="M119.1,240.8c-2.8,0.4-5.5-1.5-6-4.3c-0.4-2.8,1.5-5.5,4.3-6c2.8-0.5,5.5,1.5,6,4.3
	C123.9,237.7,122,240.3,119.1,240.8"/>
                <path class="st1" d="M122.7,252.6c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3
	C127,248.8,125.5,251.7,122.7,252.6"/>
                <path class="st1" d="M128.2,263.7c-2.6,1.3-5.7,0.3-7-2.3c-1.3-2.6-0.3-5.7,2.3-7c2.6-1.3,5.7-0.3,7,2.3
	C131.8,259.2,130.7,262.4,128.2,263.7"/>
                <path class="st1" d="M135.3,273.8c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2
	C138.1,268.8,137.6,272.1,135.3,273.8"/>
                <path class="st1" d="M143.9,282.6c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0C145.9,277.3,145.9,280.6,143.9,282.6" />
                <path class="st1" d="M153.8,290c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2
	C154.9,284.5,155.5,287.7,153.8,290"/>
                <path class="st0" d="M164.7,295.8c-1.3,2.6-4.4,3.6-7,2.3c-2.6-1.3-3.6-4.4-2.3-7c1.3-2.6,4.4-3.6,7-2.3
	C165,290.1,166,293.3,164.7,295.8"/>
                <path class="st0" d="M176.4,299.8c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3
	C175.8,294.2,177.3,297.1,176.4,299.8"/>
                <path class="st0" d="M188.5,301.9c-0.4,2.8-3.1,4.8-6,4.3c-2.8-0.4-4.8-3.1-4.3-6c0.5-2.8,3.1-4.8,6-4.3
	C187,296.4,189,299.1,188.5,301.9"/>
                <path class="st2" d="M200.9,302.1c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C198.5,296.9,200.9,299.3,200.9,302.1"/>
                <path class="st0" d="M213.1,300.4c0.4,2.8-1.5,5.5-4.3,6c-2.8,0.4-5.5-1.5-6-4.3c-0.4-2.8,1.5-5.5,4.3-6
	C210,295.6,212.7,297.5,213.1,300.4"/>
                <path class="st1" d="M224.9,296.8c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6
	C221.1,292.5,224,294,224.9,296.8"/>
                <path class="st1" d="M236,291.3c1.3,2.6,0.3,5.7-2.3,7c-2.6,1.3-5.7,0.3-7-2.3c-1.3-2.6-0.3-5.7,2.3-7
	C231.6,287.7,234.7,288.8,236,291.3"/>
                <path class="st1" d="M246.1,284.2c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3
	C241.2,281.4,244.4,281.9,246.1,284.2"/>
                <path class="st0" d="M255,275.6c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4C249.6,273.6,252.9,273.6,255,275.6" />
                <path class="st0" d="M262.4,265.7c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3
	C256.8,264.6,260,264.1,262.4,265.7"/>
                <path class="st1" d="M268.2,254.8c2.6,1.3,3.6,4.4,2.3,7c-1.3,2.6-4.4,3.6-7,2.3c-2.6-1.3-3.6-4.4-2.3-7
	C262.5,254.5,265.6,253.5,268.2,254.8"/>
                <path class="st1" d="M272.1,243.1c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6
	C266.5,243.7,269.4,242.3,272.1,243.1"/>
                <path class="st2" d="M274.3,231c2.8,0.4,4.8,3.1,4.3,6c-0.4,2.8-3.1,4.8-6,4.3c-2.8-0.5-4.8-3.1-4.3-6
	C268.8,232.5,271.4,230.5,274.3,231"/>
                <path class="st2" d="M274.5,218.6c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2
	C269.3,221,271.6,218.6,274.5,218.6"/>
                <path class="st1" d="M272.7,206.4c2.8-0.4,5.5,1.5,6,4.3c0.5,2.8-1.5,5.5-4.3,6c-2.8,0.4-5.5-1.5-6-4.3
	C267.9,209.5,269.9,206.8,272.7,206.4"/>
                <path class="st2" d="M269.1,194.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3
	C264.9,198.4,266.3,195.5,269.1,194.6"/>
                <path class="st0" d="M263.6,183.5c2.6-1.3,5.7-0.3,7,2.3c1.3,2.6,0.3,5.7-2.3,7c-2.6,1.3-5.7,0.3-7-2.3
	C260.1,187.9,261.1,184.8,263.6,183.5"/>
                <path class="st0" d="M256.5,173.4c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2
	C253.7,178.4,254.2,175.1,256.5,173.4"/>
                <path class="st0" d="M247.9,164.5c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0C245.9,169.9,245.9,166.6,247.9,164.5" />
                <path class="st1" d="M238.1,157.1c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2
	C236.9,162.7,236.4,159.5,238.1,157.1"/>
                <path class="st1" d="M227.2,151.4c1.3-2.6,4.4-3.6,7-2.3c2.6,1.3,3.6,4.4,2.3,7c-1.3,2.6-4.4,3.6-7,2.3
	C226.9,157,225.8,153.9,227.2,151.4"/>
                <path class="st2" d="M215.5,147.4c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3
	C216.1,153,214.6,150.1,215.5,147.4"/>
                <path class="st2" d="M203.3,145.2c0.4-2.8,3.1-4.8,6-4.3c2.8,0.4,4.8,3.1,4.3,6c-0.5,2.8-3.1,4.8-6,4.3
	C204.8,150.7,202.9,148.1,203.3,145.2"/>
                <path class="st1" d="M191,132.1c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,137.3,191,135,191,132.1"
                />
                <path class="st2" d="M178.3,133.7c-0.4-2.8,1.6-5.5,4.4-5.9c2.8-0.4,5.5,1.6,5.9,4.4s-1.6,5.5-4.4,5.9
	C181.3,138.5,178.7,136.6,178.3,133.7"/>
                <path class="st1" d="M165.9,137c-0.8-2.8,0.8-5.6,3.6-6.4c2.8-0.8,5.6,0.8,6.4,3.6c0.8,2.8-0.8,5.6-3.6,6.4
	C169.6,141.4,166.7,139.8,165.9,137"/>
                <path class="st1" d="M154.2,142.1c-1.2-2.6,0-5.7,2.6-6.9c2.6-1.2,5.7,0,6.9,2.6c1.2,2.6,0,5.7-2.6,6.9
	C158.4,145.9,155.4,144.7,154.2,142.1"/>
                <path class="st0" d="M143.2,148.7c-1.5-2.4-0.8-5.6,1.7-7.2s5.6-0.8,7.2,1.7c1.5,2.4,0.8,5.6-1.7,7.2
	C148,151.8,144.8,151.1,143.2,148.7"/>
                <path class="st1" d="M133.3,156.7c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3
	C138.5,159.2,135.2,158.9,133.3,156.7"/>
                <path class="st1" d="M124.6,166.1c-2.1-1.9-2.3-5.2-0.4-7.4c1.9-2.1,5.2-2.3,7.4-0.4c2.1,1.9,2.3,5.2,0.4,7.4
	C130.1,167.8,126.8,168,124.6,166.1"/>
                <path class="st1" d="M117.3,176.6c-2.4-1.6-3-4.8-1.4-7.2c1.6-2.4,4.8-3,7.2-1.4c2.4,1.6,3,4.8,1.4,7.2
	C122.9,177.5,119.7,178.2,117.3,176.6"/>
                <path class="st0" d="M111.5,188c-2.6-1.3-3.7-4.4-2.4-7c1.3-2.6,4.4-3.7,7-2.4c2.6,1.3,3.7,4.4,2.4,7
	C117.2,188.1,114.1,189.2,111.5,188"/>
                <path class="st2" d="M107.4,200c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6S110.1,200.9,107.4,200"
                />
                <path class="st2" d="M105,212.6c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6
	C110.5,211.2,107.8,213.1,105,212.6"/>
                <path class="st2" d="M104.3,225.4c-2.9-0.1-5.1-2.5-5-5.4c0.1-2.9,2.5-5.1,5.4-5c2.9,0.1,5.1,2.5,5,5.4
	C109.6,223.2,107.2,225.5,104.3,225.4"/>
                <path class="st2" d="M105.5,238.1c-2.9,0.3-5.4-1.8-5.7-4.6c-0.3-2.9,1.8-5.4,4.6-5.7c2.9-0.3,5.4,1.8,5.7,4.6
	C110.4,235.2,108.3,237.8,105.5,238.1"/>
                <path class="st1" d="M108.4,250.5c-2.8,0.7-5.6-1-6.3-3.8c-0.7-2.8,1-5.6,3.8-6.3c2.8-0.7,5.6,1,6.3,3.8
	C112.9,247,111.2,249.8,108.4,250.5"/>
                <path class="st1" d="M113,262.4c-2.7,1.1-5.7-0.2-6.8-2.9c-1.1-2.7,0.2-5.7,2.9-6.8s5.7,0.2,6.8,2.9
	C116.9,258.3,115.6,261.4,113,262.4"/>
                <path class="st1" d="M119.2,273.6c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9
	C122.5,269,121.7,272.2,119.2,273.6"/>
                <path class="st1" d="M126.9,283.8c-2.3,1.8-5.5,1.4-7.3-0.9c-1.8-2.3-1.4-5.5,0.9-7.3c2.3-1.8,5.5-1.4,7.3,0.9
	C129.6,278.8,129.2,282,126.9,283.8"/>
                <path class="st1" d="M136,292.8c-2,2.1-5.3,2.1-7.4,0.1c-2.1-2-2.1-5.3-0.1-7.4c2-2.1,5.3-2.1,7.4-0.1
	C137.9,287.5,138,290.8,136,292.8"/>
                <path class="st1" d="M146.2,300.5c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2
	C147.3,294.9,147.9,298.2,146.2,300.5"/>
                <path class="st0" d="M157.4,306.7c-1.4,2.5-4.5,3.5-7,2.2c-2.5-1.3-3.5-4.5-2.2-7c1.4-2.5,4.5-3.5,7-2.2
	C157.7,301,158.7,304.1,157.4,306.7"/>
                <path class="st1" d="M145.9,403.6c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7c1-2.7,4-4.1,6.7-3.1
	C145.5,398,146.9,400.9,145.9,403.6"/>
                <path class="st1" d="M181.8,314.1c-0.6,2.8-3.4,4.6-6.2,4c-2.8-0.6-4.6-3.4-4-6.2c0.6-2.8,3.4-4.6,6.2-4
	C180.6,308.5,182.4,311.3,181.8,314.1"/>
                <path class="st1" d="M194.5,315.2c-0.2,2.9-2.7,5-5.6,4.8c-2.9-0.2-5-2.7-4.8-5.6c0.2-2.9,2.7-5,5.6-4.8
	C192.5,309.8,194.7,312.3,194.5,315.2"/>
                <path class="st2" d="M207.2,314.5c0.2,2.9-2,5.4-4.8,5.6c-2.9,0.2-5.4-2-5.6-4.8c-0.2-2.9,2-5.4,4.8-5.6
	C204.6,309.4,207,311.6,207.2,314.5"/>
                <path class="st1" d="M219.8,312c0.6,2.8-1.2,5.6-4,6.2c-2.8,0.6-5.6-1.2-6.2-4c-0.6-2.8,1.2-5.6,4-6.2
	C216.4,307.4,219.2,309.2,219.8,312"/>
                <path class="st1" d="M231.9,307.8c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7
	C227.9,303.7,230.9,305.1,231.9,307.8"/>
                <path class="st1" d="M243.2,302c1.4,2.5,0.4,5.7-2.2,7c-2.5,1.4-5.7,0.4-7-2.2c-1.3-2.5-0.4-5.7,2.2-7
	C238.7,298.5,241.9,299.5,243.2,302"/>
                <path class="st0" d="M253.7,294.7c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3
	C248.7,291.8,252,292.3,253.7,294.7"/>
                <path class="st0" d="M263,285.9c2,2.1,1.9,5.4-0.1,7.4c-2.1,2-5.4,1.9-7.4-0.1c-2-2.1-1.9-5.4,0.1-7.4
	C257.7,283.8,261,283.9,263,285.9"/>
                <path class="st2" d="M271,276c2.3,1.8,2.7,5,0.9,7.3c-1.8,2.3-5,2.7-7.3,0.9c-2.3-1.8-2.7-5-0.9-7.3
	C265.5,274.6,268.8,274.2,271,276"/>
                <path class="st1" d="M277.6,265c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1
	C271.9,264.4,275.1,263.6,277.6,265"/>
                <path class="st1" d="M282.6,253.2c2.7,1.1,4,4.1,2.9,6.8c-1.1,2.7-4.1,4-6.8,2.9c-2.7-1.1-4-4.1-2.9-6.8
	C276.9,253.5,279.9,252.2,282.6,253.2"/>
                <path class="st1" d="M285.9,240.9c2.8,0.7,4.5,3.5,3.8,6.3c-0.7,2.8-3.5,4.5-6.3,3.8c-2.8-0.7-4.5-3.5-3.8-6.3
	C280.2,241.9,283.1,240.2,285.9,240.9"/>
                <path class="st2" d="M287.4,228.2c2.9,0.3,4.9,2.9,4.6,5.7c-0.3,2.9-2.9,4.9-5.7,4.6c-2.9-0.3-4.9-2.9-4.6-5.7
	C282,230,284.5,227.9,287.4,228.2"/>
                <path class="st2" d="M287.1,215.4c2.9-0.1,5.3,2.1,5.4,5c0.1,2.9-2.1,5.3-5,5.4c-2.9,0.1-5.3-2.1-5.4-5
	C282,217.9,284.3,215.5,287.1,215.4"/>
                <path class="st0" d="M285.1,202.8c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2
	C280.4,206,282.3,203.3,285.1,202.8"/>
                <path class="st2" d="M281.4,190.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3
	C277.1,194.4,278.6,191.5,281.4,190.6"/>
                <path class="st1" d="M275.9,179c2.6-1.3,5.7-0.2,7,2.4c1.3,2.6,0.2,5.7-2.4,7c-2.6,1.3-5.7,0.2-7-2.4
	C272.3,183.4,273.4,180.3,275.9,179"/>
                <path class="st0" d="M269,168.3c2.4-1.6,5.6-1,7.2,1.4c1.6,2.4,1,5.6-1.4,7.2c-2.4,1.6-5.6,1-7.2-1.4
	C265.9,173.2,266.6,169.9,269,168.3"/>
                <path class="st0" d="M260.6,158.7c2.1-1.9,5.4-1.8,7.4,0.4c1.9,2.1,1.8,5.4-0.4,7.4c-2.1,1.9-5.4,1.8-7.4-0.4
	C258.2,163.9,258.4,160.6,260.6,158.7"/>
                <path class="st1" d="M250.9,150.3c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6
	C249.3,155.8,249.1,152.5,250.9,150.3"/>
                <path class="st1" d="M240.2,143.4c1.5-2.4,4.7-3.2,7.2-1.7c2.4,1.5,3.2,4.7,1.7,7.2c-1.5,2.4-4.7,3.2-7.2,1.7
	C239.4,149.1,238.6,145.8,240.2,143.4"/>
                <path class="st1" d="M228.6,138c1.2-2.6,4.2-3.8,6.9-2.6c2.6,1.2,3.8,4.2,2.6,6.9c-1.2,2.6-4.2,3.8-6.9,2.6
	C228.6,143.7,227.4,140.7,228.6,138"/>
                <path class="st2" d="M216.4,134.3c0.8-2.8,3.7-4.4,6.4-3.6c2.8,0.8,4.4,3.7,3.6,6.4c-0.8,2.8-3.7,4.4-6.4,3.6
	C217.2,140,215.6,137.1,216.4,134.3"/>
                <path class="st1" d="M203.7,132.3c0.4-2.8,3-4.8,5.9-4.4c2.8,0.4,4.8,3,4.4,5.9c-0.4,2.8-3,4.8-5.9,4.4
	C205.3,137.8,203.3,135.2,203.7,132.3"/>
                <path class="st1" d="M191,119.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2
	C193.3,124.4,191,122.1,191,119.2"/>
                <path class="st2" d="M178.6,120.5c-0.3-2.9,1.7-5.4,4.6-5.8c2.9-0.3,5.4,1.7,5.8,4.6c0.3,2.9-1.7,5.4-4.6,5.8
	C181.6,125.4,179,123.4,178.6,120.5"/>
                <path class="st2" d="M166.5,123.3c-0.7-2.8,1-5.6,3.8-6.3c2.8-0.7,5.6,1,6.3,3.8c0.7,2.8-1,5.6-3.8,6.3
	C170,127.8,167.2,126,166.5,123.3"/>
                <path class="st1" d="M143.8,132.9c-1.3-2.6-0.3-5.7,2.3-7c2.6-1.3,5.7-0.3,7,2.3c1.3,2.6,0.3,5.7-2.3,7
	C148.2,136.5,145.1,135.5,143.8,132.9"/>
                <path class="st1" d="M133.4,139.7c-1.6-2.4-1-5.6,1.4-7.2c2.4-1.6,5.6-1,7.2,1.4c1.6,2.4,1,5.6-1.4,7.2
	C138.2,142.7,135,142.1,133.4,139.7"/>
                <path class="st1" d="M123.9,147.6c-1.9-2.2-1.6-5.5,0.5-7.3c2.2-1.9,5.5-1.6,7.3,0.5c1.9,2.2,1.6,5.5-0.5,7.3
	C129.1,150.1,125.8,149.8,123.9,147.6"/>
                <path class="st0" d="M115.4,156.7c-2.1-1.9-2.3-5.2-0.3-7.4c1.9-2.1,5.2-2.3,7.4-0.3c2.1,1.9,2.3,5.2,0.3,7.4
	C120.8,158.5,117.5,158.6,115.4,156.7"/>
                <path class="st1" d="M108.1,166.6c-2.3-1.7-2.9-4.9-1.2-7.3c1.7-2.3,4.9-2.9,7.3-1.2c2.3,1.7,2.9,4.9,1.2,7.3
	C113.6,167.8,110.4,168.3,108.1,166.6"/>
                <path class="st1" d="M101.9,177.4c-2.5-1.4-3.4-4.6-2.1-7.1c1.4-2.5,4.6-3.4,7.1-2.1c2.5,1.4,3.4,4.6,2.1,7.1
	C107.6,177.9,104.5,178.8,101.9,177.4"/>
                <path class="st0" d="M97.1,188.8c-2.7-1.1-4-4.1-2.9-6.8c1.1-2.7,4.1-4,6.8-2.9c2.7,1.1,4,4.1,2.9,6.8
	C102.8,188.6,99.8,189.9,97.1,188.8"/>
                <path class="st1" d="M93.7,200.7c-2.8-0.8-4.4-3.6-3.7-6.4c0.8-2.8,3.6-4.4,6.4-3.7c2.8,0.8,4.4,3.6,3.7,6.4
	C99.3,199.9,96.5,201.5,93.7,200.7"/>
                <path class="st1" d="M91.7,213c-2.8-0.4-4.8-3.1-4.4-5.9c0.4-2.8,3.1-4.8,5.9-4.4s4.8,3.1,4.4,5.9C97.2,211.4,94.6,213.4,91.7,213"
                />
                <path class="st1" d="M91.2,225.4c-2.9-0.1-5.1-2.5-5.1-5.3c0.1-2.9,2.5-5.1,5.3-5.1c2.9,0.1,5.1,2.5,5.1,5.3
	C96.5,223.2,94.1,225.4,91.2,225.4"/>
                <path class="st1" d="M92.1,237.7c-2.9,0.3-5.4-1.8-5.7-4.7s1.8-5.4,4.7-5.7c2.9-0.3,5.4,1.8,5.7,4.7C97.1,234.9,95,237.5,92.1,237.7
	"/>
                <path class="st0" d="M94.5,249.9c-2.8,0.6-5.6-1.2-6.2-4c-0.6-2.8,1.2-5.6,4-6.2c2.8-0.6,5.6,1.2,6.2,4
	C99.1,246.5,97.3,249.3,94.5,249.9"/>
                <path class="st0" d="M98.4,261.7c-2.7,0.9-5.7-0.5-6.6-3.3c-0.9-2.7,0.5-5.7,3.3-6.6c2.7-0.9,5.7,0.5,6.6,3.3
	C102.5,257.8,101.1,260.7,98.4,261.7"/>
                <path class="st1" d="M103.6,272.9c-2.6,1.2-5.7,0.1-6.9-2.4c-1.2-2.6-0.1-5.7,2.4-6.9c2.6-1.2,5.7-0.1,6.9,2.4
	C107.2,268.6,106.1,271.7,103.6,272.9"/>
                <path class="st0" d="M110,283.5c-2.4,1.5-5.6,0.8-7.2-1.6c-1.5-2.4-0.8-5.6,1.6-7.2c2.4-1.5,5.6-0.8,7.2,1.6
	C113.2,278.7,112.5,281.9,110,283.5"/>
                <path class="st1" d="M117.7,293.2c-2.2,1.8-5.5,1.5-7.3-0.7c-1.8-2.2-1.5-5.5,0.7-7.3c2.2-1.8,5.5-1.5,7.3,0.7
	C120.3,288.1,120,291.4,117.7,293.2"/>
                <path class="st1" d="M126.5,301.9c-2,2.1-5.3,2.1-7.4,0.1c-2.1-2-2.1-5.3-0.1-7.4c2-2.1,5.3-2.1,7.4-0.1
	C128.5,296.6,128.5,299.8,126.5,301.9"/>
                <path class="st2" d="M136.3,309.5c-1.7,2.3-5,2.7-7.3,1c-2.3-1.7-2.7-5-1-7.3c1.7-2.3,5-2.7,7.3-1C137.6,304,138,307.3,136.3,309.5"
                />
                <path class="st2" d="M146.9,316c-1.5,2.5-4.6,3.3-7.1,1.9c-2.5-1.5-3.3-4.6-1.9-7.1c1.5-2.5,4.6-3.3,7.1-1.9
	C147.5,310.3,148.3,313.5,146.9,316"/>
                <path class="st2" d="M158.2,321.1c-1.2,2.6-4.2,3.8-6.9,2.7c-2.6-1.2-3.8-4.2-2.7-6.9c1.1-2.6,4.2-3.8,6.9-2.7
	C158.1,315.4,159.3,318.5,158.2,321.1"/>
                <path class="st2" d="M170,324.8c-0.8,2.8-3.7,4.3-6.5,3.5c-2.8-0.8-4.3-3.7-3.5-6.5c0.8-2.8,3.7-4.3,6.5-3.5
	C169.2,319.2,170.8,322.1,170,324.8"/>
                <path class="st1" d="M182.1,327.2c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2
	C180.7,321.6,182.6,324.3,182.1,327.2"/>
                <path class="st1" d="M194.5,328c-0.2,2.9-2.6,5.1-5.5,4.9c-2.9-0.2-5.1-2.6-4.9-5.5c0.2-2.9,2.6-5.1,5.5-4.9
	C192.5,322.7,194.7,325.2,194.5,328"/>
                <path class="st1" d="M206.9,327.4c0.2,2.9-2,5.3-4.9,5.5c-2.9,0.2-5.3-2-5.5-4.9c-0.2-2.9,2-5.3,4.9-5.5
	C204.2,322.4,206.7,324.6,206.9,327.4"/>
                <path class="st1" d="M219.1,325.4c0.5,2.8-1.4,5.5-4.2,6.1c-2.8,0.5-5.5-1.4-6.1-4.2c-0.5-2.8,1.4-5.5,4.2-6.1
	C215.9,320.7,218.6,322.6,219.1,325.4"/>
                <path class="st0" d="M231,321.9c0.9,2.7-0.7,5.7-3.4,6.5s-5.7-0.7-6.5-3.4c-0.9-2.7,0.7-5.7,3.4-6.5
	C227.2,317.6,230.1,319.1,231,321.9"/>
                <path class="st1" d="M242.4,317c1.2,2.6,0,5.7-2.6,6.9c-2.6,1.2-5.7,0-6.9-2.6c-1.2-2.6,0-5.7,2.6-6.9
	C238.1,313.2,241.2,314.4,242.4,317"/>
                <path class="st2" d="M253.1,310.8c1.5,2.5,0.7,5.7-1.8,7.1c-2.5,1.5-5.7,0.7-7.1-1.8c-1.5-2.5-0.7-5.7,1.8-7.1
	C248.4,307.6,251.6,308.4,253.1,310.8"/>
                <path class="st2" d="M263,303.4c1.8,2.3,1.3,5.5-0.9,7.3c-2.3,1.8-5.5,1.3-7.3-0.9c-1.8-2.3-1.3-5.5,0.9-7.3
	C258,300.7,261.3,301.1,263,303.4"/>
                <path class="st2" d="M272,294.9c2,2.1,2,5.4-0.1,7.4c-2.1,2-5.4,2-7.4-0.1c-2-2.1-2-5.4,0.1-7.4C266.7,292.8,270,292.8,272,294.9" />
                <path class="st0" d="M279.9,285.3c2.2,1.8,2.6,5.1,0.8,7.3c-1.8,2.2-5.1,2.6-7.3,0.8c-2.2-1.8-2.6-5.1-0.8-7.3
	C274.4,283.9,277.7,283.5,279.9,285.3"/>
                <path class="st2" d="M286.6,274.9c2.4,1.5,3.2,4.7,1.7,7.2c-1.5,2.4-4.7,3.2-7.2,1.7c-2.4-1.5-3.2-4.7-1.7-7.2
	C281,274.1,284.2,273.4,286.6,274.9"/>
                <path class="st1" d="M292.1,263.8c2.6,1.2,3.7,4.3,2.5,6.9c-1.2,2.6-4.3,3.7-6.9,2.5c-2.6-1.2-3.7-4.3-2.5-6.9
	C286.4,263.7,289.5,262.6,292.1,263.8"/>
                <path class="st1" d="M296.1,252.1c2.7,0.9,4.2,3.9,3.3,6.6c-0.9,2.7-3.9,4.2-6.6,3.3c-2.7-0.9-4.2-3.9-3.3-6.6
	C290.5,252.6,293.4,251.2,296.1,252.1"/>
                <path class="st0" d="M298.8,240c2.8,0.6,4.6,3.3,4.1,6.1c-0.6,2.8-3.3,4.6-6.1,4.1c-2.8-0.6-4.6-3.3-4.1-6.1
	C293.2,241.2,296,239.4,298.8,240"/>
                <path class="st0" d="M300,227.6c2.9,0.2,5,2.8,4.8,5.6c-0.2,2.9-2.8,5-5.6,4.8c-2.9-0.2-5-2.8-4.8-5.6
	C294.6,229.5,297.2,227.4,300,227.6"/>
                <path class="st1" d="M299.8,215.3c2.9-0.1,5.3,2.1,5.4,5c0.1,2.9-2.1,5.3-5,5.4c-2.9,0.1-5.3-2.1-5.4-5
	C294.7,217.8,296.9,215.4,299.8,215.3"/>
                <path class="st0" d="M298.1,203c2.8-0.4,5.5,1.5,5.9,4.3c0.4,2.8-1.5,5.5-4.3,5.9c-2.8,0.4-5.5-1.5-5.9-4.3
	C293.3,206.1,295.2,203.4,298.1,203"/>
                <path class="st0" d="M294.9,191c2.8-0.8,5.6,0.8,6.4,3.6c0.8,2.8-0.8,5.6-3.6,6.4c-2.8,0.8-5.6-0.8-6.4-3.6
	C290.5,194.7,292.1,191.8,294.9,191"/>
                <path class="st1" d="M290.3,179.5c2.7-1.1,5.7,0.2,6.8,2.8c1.1,2.7-0.2,5.7-2.8,6.8c-2.7,1.1-5.7-0.2-6.8-2.8
	C286.4,183.6,287.7,180.6,290.3,179.5"/>
                <path class="st1" d="M284.5,168.6c2.5-1.4,5.7-0.5,7.1,2c1.4,2.5,0.5,5.7-2,7.1c-2.5,1.4-5.7,0.5-7.1-2
	C281,173.2,281.9,170,284.5,168.6"/>
                <path class="st1" d="M277.3,158.5c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2
	C274.5,163.4,275,160.1,277.3,158.5"/>
                <path class="st1" d="M269,149.2c2.1-2,5.4-1.8,7.4,0.3c2,2.1,1.8,5.4-0.3,7.4c-2.1,2-5.4,1.8-7.4-0.3
	C266.8,154.5,266.9,151.2,269,149.2"/>
                <path class="st1" d="M259.7,141.1c1.9-2.2,5.1-2.5,7.3-0.6c2.2,1.9,2.5,5.1,0.6,7.3c-1.9,2.2-5.1,2.5-7.3,0.6
	C258.1,146.6,257.8,143.3,259.7,141.1"/>
                <path class="st1" d="M238.5,128.3c1.3-2.6,4.4-3.6,7-2.3c2.6,1.3,3.6,4.4,2.3,7c-1.3,2.6-4.4,3.6-7,2.3
	C238.2,134,237.2,130.9,238.5,128.3"/>
                <path class="st1" d="M226.9,123.9c1-2.7,4-4.1,6.7-3.1c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1
	C227.3,129.6,225.9,126.6,226.9,123.9"/>
                <path class="st1" d="M214.9,120.9c0.7-2.8,3.5-4.5,6.3-3.9c2.8,0.7,4.5,3.5,3.9,6.3c-0.7,2.8-3.5,4.5-6.3,3.9
	C216,126.5,214.3,123.7,214.9,120.9"/>
                <path class="st1" d="M202.6,119.4c0.3-2.9,2.9-4.9,5.8-4.6c2.9,0.3,4.9,2.9,4.6,5.8c-0.3,2.9-2.9,4.9-5.8,4.6
	C204.4,124.8,202.3,122.2,202.6,119.4"/>
                <path class="st1" d="M191,106.3c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2
	C193.3,111.5,191,109.2,191,106.3"/>
                <path class="st2" d="M165.9,110.1c-0.6-2.8,1.2-5.6,4-6.2c2.8-0.6,5.6,1.2,6.2,4c0.6,2.8-1.2,5.6-4,6.2
	C169.3,114.7,166.5,112.9,165.9,110.1"/>
                <path class="st0" d="M153.8,114c-0.9-2.7,0.5-5.7,3.3-6.6c2.7-0.9,5.7,0.5,6.6,3.3c0.9,2.7-0.5,5.7-3.3,6.6
	C157.7,118.2,154.7,116.8,153.8,114"/>
                <path class="st0" d="M142.2,119.2c-1.2-2.6-0.1-5.7,2.5-6.9c2.6-1.2,5.7-0.1,6.9,2.5c1.2,2.6,0.1,5.7-2.5,6.9
	C146.5,123,143.4,121.8,142.2,119.2"/>
                <path class="st2" d="M131.3,125.6c-1.5-2.5-0.7-5.7,1.8-7.1c2.5-1.5,5.7-0.7,7.1,1.8c1.5,2.5,0.7,5.7-1.8,7.1
	C135.9,128.9,132.7,128.1,131.3,125.6"/>
                <path class="st0" d="M121.1,133.2c-1.7-2.3-1.3-5.6,1-7.3c2.3-1.7,5.6-1.3,7.3,1c1.7,2.3,1.3,5.6-1,7.3
	C126.1,135.9,122.8,135.5,121.1,133.2"/>
                <path class="st0" d="M111.7,141.8c-2-2.1-1.9-5.4,0.2-7.4c2.1-2,5.4-1.9,7.4,0.2c2,2.1,1.9,5.4-0.2,7.4
	C117,144,113.7,143.9,111.7,141.8"/>
                <path class="st0" d="M103.4,151.4c-2.2-1.9-2.5-5.1-0.6-7.3c1.9-2.2,5.1-2.5,7.3-0.6c2.2,1.9,2.5,5.1,0.6,7.3
	C108.9,153,105.6,153.2,103.4,151.4"/>
                <path class="st0" d="M96.1,161.8c-2.4-1.6-3-4.9-1.4-7.2c1.6-2.4,4.9-3,7.2-1.4c2.4,1.6,3,4.9,1.4,7.2
	C101.8,162.8,98.5,163.4,96.1,161.8"/>
                <path class="st1" d="M90,172.9c-2.5-1.3-3.5-4.5-2.2-7c1.3-2.5,4.5-3.5,7-2.2c2.5,1.4,3.5,4.5,2.2,7C95.7,173.3,92.6,174.3,90,172.9
	"/>
                <path class="st1" d="M85.2,184.6c-2.7-1.1-4-4.1-2.9-6.8c1.1-2.7,4.1-4,6.8-2.9c2.7,1.1,4,4.1,2.9,6.8
	C90.9,184.4,87.9,185.7,85.2,184.6"/>
                <path class="st1" d="M81.6,196.8c-2.8-0.8-4.4-3.6-3.6-6.4c0.8-2.8,3.6-4.4,6.4-3.6c2.8,0.8,4.4,3.6,3.6,6.4
	C87.3,196,84.4,197.6,81.6,196.8"/>
                <path class="st0" d="M79.4,209.3c-2.8-0.5-4.8-3.2-4.3-6c0.5-2.8,3.2-4.8,6-4.3c2.8,0.5,4.8,3.2,4.3,6
	C84.9,207.9,82.2,209.8,79.4,209.3"/>
                <path class="st0" d="M78.6,222c-2.9-0.2-5.1-2.6-4.9-5.5c0.2-2.9,2.6-5.1,5.5-4.9c2.9,0.2,5.1,2.6,4.9,5.5
	C83.9,220,81.4,222.2,78.6,222"/>
                <path class="st2" d="M79.1,234.7c-2.9,0.2-5.3-2.1-5.5-4.9c-0.2-2.9,2.1-5.3,4.9-5.5c2.9-0.2,5.3,2.1,5.5,4.9
	C84.1,232.1,81.9,234.5,79.1,234.7"/>
                <path class="st0" d="M80.9,247.2c-2.8,0.5-5.5-1.5-6-4.3c-0.5-2.8,1.5-5.5,4.3-6c2.8-0.5,5.5,1.5,6,4.3
	C85.7,244.1,83.8,246.8,80.9,247.2"/>
                <path class="st0" d="M84.2,259.5c-2.8,0.8-5.6-0.9-6.4-3.6c-0.8-2.8,0.9-5.6,3.6-6.4c2.8-0.8,5.6,0.9,6.4,3.6
	C88.6,255.9,86.9,258.7,84.2,259.5"/>
                <path class="st2" d="M88.7,271.4c-2.7,1.1-5.7-0.3-6.8-2.9c-1.1-2.7,0.2-5.7,2.9-6.8c2.7-1.1,5.7,0.3,6.8,2.9
	C92.7,267.3,91.4,270.3,88.7,271.4"/>
                <path class="st1" d="M94.5,282.7c-2.5,1.3-5.7,0.4-7-2.2c-1.3-2.5-0.4-5.7,2.2-7c2.5-1.3,5.7-0.4,7,2.2
	C98,278.2,97,281.3,94.5,282.7"/>
                <path class="st1" d="M101.5,293.3c-2.4,1.6-5.6,1-7.2-1.4c-1.6-2.4-1-5.6,1.4-7.2c2.4-1.6,5.6-1,7.2,1.4
	C104.5,288.4,103.9,291.7,101.5,293.3"/>
                <path class="st1" d="M109.5,303.1c-2.2,1.9-5.5,1.6-7.3-0.6c-1.9-2.2-1.6-5.5,0.6-7.3c2.2-1.9,5.5-1.6,7.3,0.6
	C112,297.9,111.7,301.2,109.5,303.1"/>
                <path class="st2" d="M37.9,326.2c-2,2.1-5.3,2.2-7.4,0.2c-2.1-2-2.2-5.3-0.2-7.4c2-2.1,5.3-2.2,7.4-0.2
	C39.8,320.8,39.9,324.1,37.9,326.2"/>
                <path class="st1" d="M128.6,319.8c-1.7,2.3-5,2.7-7.3,1c-2.3-1.7-2.7-5-1-7.3c1.7-2.3,5-2.7,7.3-1
	C129.9,314.2,130.4,317.5,128.6,319.8"/>
                <path class="st2" d="M139.4,326.5c-1.5,2.5-4.7,3.2-7.1,1.8c-2.5-1.5-3.2-4.7-1.8-7.2c1.5-2.5,4.7-3.2,7.1-1.8
	C140.1,320.8,140.9,324,139.4,326.5"/>
                <path class="st2" d="M150.9,332c-1.2,2.6-4.3,3.7-6.9,2.5c-2.6-1.2-3.7-4.3-2.5-6.9c1.2-2.6,4.3-3.7,6.9-2.5
	C150.9,326.3,152.1,329.4,150.9,332"/>
                <path class="st2" d="M162.8,336.2c-0.9,2.7-3.9,4.2-6.6,3.3c-2.7-0.9-4.2-3.9-3.3-6.6c0.9-2.7,3.9-4.2,6.6-3.3
	C162.3,330.5,163.7,333.5,162.8,336.2"/>
                <path class="st2" d="M175.2,339.1c-0.6,2.8-3.4,4.6-6.2,3.9c-2.8-0.6-4.6-3.4-3.9-6.2c0.6-2.8,3.4-4.6,6.2-3.9
	C174,333.5,175.8,336.3,175.2,339.1"/>
                <path class="st1" d="M187.8,340.7c-0.3,2.9-2.9,4.9-5.8,4.6c-2.9-0.3-4.9-2.9-4.6-5.8c0.3-2.9,2.9-4.9,5.8-4.6
	C186,335.2,188.1,337.8,187.8,340.7"/>
                <path class="st1" d="M200.5,340.9c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.4-5.2,5.2-5.2
	C198.2,335.7,200.5,338,200.5,340.9"/>
                <path class="st1" d="M213.1,339.7c0.3,2.9-1.8,5.4-4.6,5.7c-2.9,0.3-5.4-1.8-5.7-4.6c-0.3-2.9,1.8-5.4,4.6-5.7
	C210.2,334.8,212.8,336.8,213.1,339.7"/>
                <path class="st1" d="M225.5,337.2c0.6,2.8-1.2,5.6-4,6.2c-2.8,0.6-5.6-1.2-6.2-4s1.2-5.6,4-6.2C222.2,332.6,224.9,334.3,225.5,337.2
	"/>
                <path class="st1" d="M237.6,333.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6
	C233.8,329.1,236.7,330.6,237.6,333.3"/>
                <path class="st1" d="M249.2,328.1c1.2,2.6,0,5.7-2.6,6.9c-2.6,1.2-5.7,0-6.9-2.6c-1.2-2.6,0-5.7,2.6-6.9
	C245,324.4,248,325.5,249.2,328.1"/>
                <path class="st2" d="M260.2,321.8c1.5,2.5,0.7,5.7-1.8,7.1c-2.5,1.5-5.7,0.7-7.1-1.8c-1.5-2.5-0.7-5.7,1.8-7.1
	C255.6,318.5,258.8,319.3,260.2,321.8"/>
                <path class="st2" d="M270.5,314.3c1.7,2.3,1.3,5.6-1,7.3c-2.3,1.7-5.6,1.3-7.3-1c-1.7-2.3-1.3-5.6,1-7.3
	C265.5,311.5,268.7,312,270.5,314.3"/>
                <path class="st2" d="M279.8,305.7c2,2.1,1.9,5.4-0.2,7.4c-2.1,2-5.4,1.9-7.4-0.2c-2-2.1-1.9-5.4,0.2-7.4
	C274.5,303.5,277.8,303.6,279.8,305.7"/>
                <path class="st0" d="M288.2,296.1c2.2,1.9,2.4,5.2,0.6,7.3c-1.9,2.2-5.2,2.4-7.3,0.6c-2.2-1.9-2.4-5.2-0.6-7.3
	C282.7,294.5,286,294.3,288.2,296.1"/>
                <path class="st2" d="M295.5,285.7c2.4,1.6,3,4.9,1.4,7.2c-1.6,2.4-4.9,3-7.2,1.4c-2.4-1.6-3-4.9-1.4-7.2
	C289.9,284.7,293.1,284.1,295.5,285.7"/>
                <path class="st2" d="M301.6,274.6c2.5,1.4,3.5,4.5,2.1,7c-1.4,2.5-4.5,3.5-7,2.1c-2.5-1.4-3.5-4.5-2.1-7
	C295.9,274.2,299.1,273.3,301.6,274.6"/>
                <path class="st1" d="M306.5,262.9c2.7,1.1,4,4.1,2.9,6.8c-1.1,2.7-4.1,4-6.8,2.9c-2.7-1.1-4-4.1-2.9-6.8
	C300.8,263.1,303.8,261.8,306.5,262.9"/>
                <path class="st0" d="M310.1,250.7c2.8,0.8,4.4,3.7,3.6,6.4c-0.8,2.8-3.7,4.4-6.4,3.6c-2.8-0.8-4.4-3.7-3.6-6.4
	C304.5,251.6,307.3,250,310.1,250.7"/>
                <path class="st0" d="M312.4,238.3c2.8,0.5,4.7,3.2,4.3,6c-0.5,2.8-3.2,4.7-6,4.3c-2.8-0.5-4.7-3.2-4.3-6
	C306.8,239.7,309.5,237.8,312.4,238.3"/>
                <path class="st0" d="M313.3,225.6c2.9,0.2,5.1,2.6,4.9,5.5c-0.2,2.9-2.6,5.1-5.5,4.9c-2.9-0.2-5.1-2.6-4.9-5.5
	C307.9,227.6,310.4,225.4,313.3,225.6"/>
                <path class="st1" d="M312.8,212.9c2.9-0.1,5.3,2.1,5.5,4.9c0.1,2.9-2.1,5.3-4.9,5.5c-2.9,0.1-5.3-2.1-5.5-4.9
	C307.7,215.5,309.9,213,312.8,212.9"/>
                <path class="st1" d="M311,200.3c2.8-0.4,5.5,1.5,6,4.3c0.5,2.8-1.5,5.5-4.3,6c-2.8,0.4-5.5-1.5-6-4.3
	C306.2,203.5,308.1,200.8,311,200.3"/>
                <path class="st0" d="M307.8,188.1c2.8-0.8,5.6,0.9,6.4,3.7c0.8,2.8-0.9,5.6-3.7,6.4c-2.8,0.8-5.6-0.9-6.4-3.7
	C303.4,191.7,305,188.8,307.8,188.1"/>
                <path class="st1" d="M303.3,176.2c2.7-1,5.7,0.3,6.7,2.9c1,2.7-0.3,5.7-2.9,6.7c-2.7,1.1-5.7-0.3-6.7-2.9
	C299.3,180.3,300.6,177.2,303.3,176.2"/>
                <path class="st1" d="M297.5,164.9c2.5-1.3,5.7-0.3,7,2.2c1.3,2.5,0.3,5.7-2.2,7c-2.5,1.3-5.7,0.3-7-2.2
	C294,169.3,295,166.2,297.5,164.9"/>
                <path class="st1" d="M290.6,154.2c2.4-1.6,5.6-1,7.2,1.4s1,5.6-1.4,7.2c-2.4,1.6-5.6,1-7.2-1.4C287.6,159.1,288.2,155.8,290.6,154.2
	"/>
                <path class="st1" d="M282.6,144.4c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6
	C280.1,149.5,280.4,146.3,282.6,144.4"/>
                <path class="st0" d="M273.5,135.5c2-2.1,5.3-2.1,7.4-0.2c2.1,2,2.1,5.3,0.2,7.4c-2,2.1-5.3,2.1-7.4,0.2
	C271.6,140.9,271.5,137.6,273.5,135.5"/>
                <path class="st1" d="M263.5,127.7c1.8-2.3,5-2.7,7.3-0.9c2.3,1.8,2.7,5,0.9,7.3c-1.8,2.3-5,2.7-7.3,0.9
	C262.2,133.2,261.8,129.9,263.5,127.7"/>
                <path class="st1" d="M252.8,120.9c1.5-2.5,4.7-3.2,7.2-1.7c2.5,1.5,3.2,4.7,1.7,7.2c-1.5,2.5-4.7,3.2-7.2,1.7
	C252.1,126.6,251.3,123.4,252.8,120.9"/>
                <path class="st1" d="M241.4,115.4c1.2-2.6,4.3-3.7,6.9-2.5c2.6,1.2,3.7,4.3,2.5,6.9c-1.2,2.6-4.3,3.7-6.9,2.5
	C241.2,121.1,240.1,118,241.4,115.4"/>
                <path class="st1" d="M229.4,111.1c0.9-2.7,3.9-4.2,6.6-3.2c2.7,0.9,4.2,3.9,3.2,6.6c-0.9,2.7-3.9,4.2-6.6,3.2
	C229.9,116.8,228.5,113.8,229.4,111.1"/>
                <path class="st0" d="M217.1,108.1c0.6-2.8,3.4-4.6,6.2-3.9c2.8,0.6,4.6,3.4,3.9,6.2c-0.6,2.8-3.4,4.6-6.2,3.9
	C218.2,113.7,216.4,110.9,217.1,108.1"/>
                <path class="st0" d="M204.5,106.5c0.3-2.9,2.9-4.9,5.8-4.6c2.9,0.3,4.9,2.9,4.6,5.8c-0.3,2.9-2.9,4.9-5.8,4.6
	C206.2,112,204.1,109.4,204.5,106.5"/>
                <path class="st1" d="M191,93.4c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,98.6,191,96.3,191,93.4" />
                <path class="st1" d="M177.4,94.6c-0.3-2.9,1.8-5.4,4.6-5.7c2.9-0.3,5.4,1.8,5.7,4.6c0.3,2.9-1.8,5.4-4.6,5.7
	C180.2,99.6,177.7,97.5,177.4,94.6"/>
                <path class="st1" d="M164,97.3c-0.6-2.8,1.2-5.6,4-6.2c2.8-0.6,5.6,1.2,6.2,4c0.6,2.8-1.2,5.6-4,6.2
	C167.4,101.9,164.6,100.1,164,97.3"/>
                <path class="st1" d="M151,101.3c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6
	C154.8,105.5,151.9,104.1,151,101.3"/>
                <path class="st0" d="M138.4,106.7c-1.2-2.6,0-5.7,2.6-6.9c2.6-1.2,5.7,0,6.9,2.6c1.2,2.6,0,5.7-2.6,6.9
	C142.7,110.5,139.6,109.3,138.4,106.7"/>
                <path class="st2" d="M126.5,113.3c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1
	C131.2,116.7,128,115.8,126.5,113.3"/>
                <path class="st1" d="M115.4,121.2c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3
	C120.3,124,117.1,123.5,115.4,121.2"/>
                <path class="st0" d="M105.1,130.2c-1.9-2.1-1.8-5.4,0.4-7.4c2.1-1.9,5.4-1.8,7.4,0.4c1.9,2.1,1.8,5.4-0.4,7.4
	C110.3,132.5,107,132.3,105.1,130.2"/>
                <path class="st0" d="M95.9,140.2c-2.1-1.9-2.3-5.2-0.4-7.4c1.9-2.1,5.2-2.3,7.4-0.4c2.1,1.9,2.3,5.2,0.4,7.4
	C101.3,141.9,98,142.1,95.9,140.2"/>
                <path class="st1" d="M87.7,151.1c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3
	C93.3,152.3,90,152.8,87.7,151.1"/>
                <path class="st1" d="M80.7,162.8c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1
	C86.4,163.4,83.2,164.2,80.7,162.8"/>
                <path class="st1" d="M75,175.2c-2.6-1.2-3.8-4.2-2.6-6.9c1.2-2.6,4.2-3.8,6.9-2.6c2.6,1.2,3.8,4.2,2.6,6.9
	C80.7,175.2,77.6,176.3,75,175.2"/>
                <path class="st2" d="M70.6,188.1c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6
	C76.2,187.5,73.3,189,70.6,188.1"/>
                <path class="st2" d="M67.6,201.4c-2.8-0.6-4.6-3.4-4-6.2c0.6-2.8,3.4-4.6,6.2-4c2.8,0.6,4.6,3.4,4,6.2
	C73.1,200.2,70.4,202,67.6,201.4"/>
                <path class="st0" d="M65.9,214.9c-2.9-0.3-4.9-2.9-4.6-5.7c0.3-2.9,2.9-4.9,5.7-4.6c2.9,0.3,4.9,2.9,4.6,5.7
	C71.4,213.1,68.8,215.2,65.9,214.9"/>
                <path class="st0" d="M65.7,228.5c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2
	C70.9,226.2,68.6,228.5,65.7,228.5"/>
                <path class="st2" d="M67,242.1c-2.9,0.3-5.4-1.8-5.7-4.6c-0.3-2.9,1.8-5.4,4.6-5.7c2.9-0.3,5.4,1.8,5.7,4.6
	C71.9,239.3,69.8,241.8,67,242.1"/>
                <path class="st0" d="M69.6,255.5c-2.8,0.6-5.6-1.2-6.2-4c-0.6-2.8,1.2-5.6,4-6.2c2.8-0.6,5.6,1.2,6.2,4
	C74.2,252.1,72.4,254.9,69.6,255.5"/>
                <path class="st2" d="M73.6,268.5c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3
	C77.9,264.7,76.4,267.6,73.6,268.5"/>
                <path class="st1" d="M79,281.1c-2.6,1.2-5.7,0-6.9-2.6c-1.2-2.6,0-5.7,2.6-6.9c2.6-1.2,5.7,0,6.9,2.6
	C82.8,276.8,81.6,279.9,79,281.1"/>
                <path class="st1" d="M85.7,293c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9
	C89,288.4,88.2,291.5,85.7,293"/>
                <path class="st0" d="M93.5,304.1c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2
	C96.4,299.2,95.8,302.4,93.5,304.1"/>
                <path class="st0" d="M102.5,314.4c-2.1,1.9-5.4,1.8-7.4-0.4c-1.9-2.1-1.8-5.4,0.4-7.4c2.1-1.9,5.4-1.8,7.4,0.4
	C104.8,309.2,104.6,312.5,102.5,314.4"/>
                <path class="st1" d="M112.5,323.6c-1.9,2.1-5.2,2.3-7.4,0.4c-2.1-1.9-2.3-5.2-0.4-7.4c1.9-2.1,5.2-2.3,7.4-0.4
	C114.2,318.2,114.4,321.5,112.5,323.6"/>
                <path class="st1" d="M123.4,331.8c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2
	C124.6,326.2,125.1,329.5,123.4,331.8"/>
                <path class="st2" d="M135.1,338.8c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9
	C135.7,333.1,136.6,336.3,135.1,338.8"/>
                <path class="st2" d="M147.5,344.5c-1.2,2.6-4.2,3.8-6.9,2.6c-2.6-1.2-3.8-4.2-2.6-6.9c1.2-2.6,4.2-3.8,6.9-2.6
	C147.5,338.8,148.7,341.9,147.5,344.5"/>
                <path class="st2" d="M160.4,348.9c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3
	C159.8,343.3,161.3,346.2,160.4,348.9"/>
                <path class="st2" d="M173.7,352c-0.6,2.8-3.4,4.6-6.2,4c-2.8-0.6-4.6-3.4-4-6.2c0.6-2.8,3.4-4.6,6.2-4
	C172.5,346.4,174.3,349.1,173.7,352"/>
                <path class="st1" d="M187.2,353.6c-0.3,2.9-2.9,4.9-5.7,4.6c-2.9-0.3-4.9-2.9-4.6-5.7c0.3-2.9,2.9-4.9,5.7-4.6
	C185.5,348.1,187.5,350.7,187.2,353.6"/>
                <path class="st0" d="M200.9,353.8c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C198.5,348.6,200.9,350.9,200.9,353.8"/>
                <path class="st0" d="M214.5,352.5c0.3,2.9-1.8,5.4-4.6,5.7c-2.9,0.3-5.4-1.8-5.7-4.6c-0.3-2.9,1.8-5.4,4.6-5.7
	C211.6,347.6,214.2,349.7,214.5,352.5"/>
                <path class="st0" d="M227.8,349.9c0.6,2.8-1.2,5.6-4,6.2c-2.8,0.6-5.6-1.2-6.2-4c-0.6-2.8,1.2-5.6,4-6.2
	C224.5,345.3,227.2,347.1,227.8,349.9"/>
                <path class="st1" d="M240.9,345.9c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6
	C237,341.6,240,343.1,240.9,345.9"/>
                <path class="st1" d="M253.4,340.5c1.2,2.6,0,5.7-2.6,6.9c-2.6,1.2-5.7,0-6.9-2.6c-1.2-2.6,0-5.7,2.6-6.9
	C249.1,336.7,252.2,337.9,253.4,340.5"/>
                <path class="st0" d="M265.3,333.8c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1
	C260.7,330.5,263.9,331.4,265.3,333.8"/>
                <path class="st2" d="M276.4,326c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3
	C271.5,323.1,274.8,323.7,276.4,326"/>
                <path class="st2" d="M286.7,317c1.9,2.1,1.8,5.4-0.4,7.4c-2.1,1.9-5.4,1.8-7.4-0.4c-1.9-2.1-1.8-5.4,0.4-7.4
	C281.5,314.7,284.8,314.9,286.7,317"/>
                <path class="st2" d="M296,307c2.1,1.9,2.3,5.2,0.4,7.4c-1.9,2.1-5.2,2.3-7.4,0.4c-2.1-1.9-2.3-5.2-0.4-7.4
	C290.5,305.3,293.8,305.1,296,307"/>
                <path class="st2" d="M304.1,296.1c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3
	C298.6,294.9,301.8,294.4,304.1,296.1"/>
                <path class="st2" d="M311.1,284.4c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1
	C305.5,283.8,308.6,282.9,311.1,284.4"/>
                <path class="st2" d="M316.9,272c2.6,1.2,3.8,4.2,2.6,6.9c-1.2,2.6-4.2,3.8-6.9,2.6c-2.6-1.2-3.8-4.2-2.6-6.9
	C311.2,272,314.2,270.8,316.9,272"/>
                <path class="st1" d="M321.3,259.1c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6
	C315.6,259.7,318.5,258.2,321.3,259.1"/>
                <path class="st2" d="M324.3,245.8c2.8,0.6,4.6,3.4,4,6.2c-0.6,2.8-3.4,4.6-6.2,4c-2.8-0.6-4.6-3.4-4-6.2
	C318.7,247,321.5,245.2,324.3,245.8"/>
                <path class="st0" d="M325.9,232.3c2.9,0.3,4.9,2.9,4.6,5.7c-0.3,2.9-2.9,4.9-5.7,4.6c-2.9-0.3-4.9-2.9-4.6-5.7
	C320.5,234,323,232,325.9,232.3"/>
                <path class="st1" d="M326.1,218.6c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2
	C320.9,221,323.2,218.6,326.1,218.6"/>
                <path class="st1" d="M324.9,205c2.9-0.3,5.4,1.8,5.7,4.6c0.3,2.9-1.8,5.4-4.6,5.7c-2.9,0.3-5.4-1.8-5.7-4.6
	C319.9,207.9,322,205.3,324.9,205"/>
                <path class="st1" d="M322.2,191.7c2.8-0.6,5.6,1.2,6.2,4c0.6,2.8-1.2,5.6-4,6.2c-2.8,0.6-5.6-1.2-6.2-4
	C317.6,195,319.4,192.3,322.2,191.7"/>
                <path class="st1" d="M318.2,178.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3
	C314,182.5,315.5,179.5,318.2,178.6"/>
                <path class="st1" d="M312.8,166.1c2.6-1.2,5.7,0,6.9,2.6c1.2,2.6,0,5.7-2.6,6.9c-2.6,1.2-5.7,0-6.9-2.6
	C309,170.4,310.2,167.3,312.8,166.1"/>
                <path class="st1" d="M306.2,154.2c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9
	C302.8,158.8,303.7,155.6,306.2,154.2"/>
                <path class="st1" d="M298.3,143.1c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2
	C295.5,148,296,144.7,298.3,143.1"/>
                <path class="st2" d="M289.3,132.8c2.1-1.9,5.4-1.8,7.4,0.4c1.9,2.1,1.8,5.4-0.4,7.4c-2.1,1.9-5.4,1.8-7.4-0.4
	C287,138,287.2,134.7,289.3,132.8"/>
                <path class="st2" d="M279.3,123.5c1.9-2.1,5.2-2.3,7.4-0.4c2.1,1.9,2.3,5.2,0.4,7.4c-1.9,2.1-5.2,2.3-7.4,0.4
	C277.6,129,277.4,125.7,279.3,123.5"/>
                <path class="st0" d="M268.4,115.4c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2
	C267.2,120.9,266.7,117.7,268.4,115.4"/>
                <path class="st1" d="M256.7,108.4c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9
	C256.1,114,255.3,110.9,256.7,108.4"/>
                <path class="st1" d="M244.3,102.6c1.2-2.6,4.2-3.8,6.9-2.6c2.6,1.2,3.8,4.2,2.6,6.9c-1.2,2.6-4.2,3.8-6.9,2.6
	C244.3,108.4,243.2,105.3,244.3,102.6"/>
                <path class="st1" d="M231.4,98.2c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3
	C232,103.9,230.5,101,231.4,98.2"/>
                <path class="st2" d="M218.1,95.2c0.6-2.8,3.4-4.6,6.2-4c2.8,0.6,4.6,3.4,4,6.2c-0.6,2.8-3.4,4.6-6.2,4
	C219.3,100.8,217.5,98,218.1,95.2"/>
                <path class="st2" d="M204.6,93.6c0.3-2.9,2.9-4.9,5.7-4.6c2.9,0.3,4.9,2.9,4.6,5.7c-0.3,2.9-2.9,4.9-5.7,4.6
	C206.4,99,204.3,96.5,204.6,93.6"/>
                <path class="st0" d="M191,80.5c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,85.7,191,83.4,191,80.5" />
                <path class="st0" d="M178.5,81.5c-0.2-2.9,1.9-5.4,4.7-5.6c2.9-0.2,5.4,1.9,5.6,4.7c0.2,2.9-1.9,5.4-4.7,5.6
	C181.3,86.5,178.8,84.3,178.5,81.5"/>
                <path class="st0" d="M166.2,83.5c-0.5-2.8,1.4-5.5,4.2-6c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6
	C169.4,88.3,166.7,86.4,166.2,83.5"/>
                <path class="st1" d="M154.1,86.7c-0.7-2.8,0.9-5.6,3.7-6.4c2.8-0.7,5.6,0.9,6.4,3.7c0.7,2.8-0.9,5.6-3.7,6.4
	C157.7,91.1,154.8,89.4,154.1,86.7"/>
                <path class="st0" d="M142.3,90.8c-1-2.7,0.4-5.7,3.1-6.7c2.7-1,5.7,0.4,6.7,3.1c1,2.7-0.4,5.7-3.1,6.7
	C146.3,94.9,143.3,93.5,142.3,90.8"/>
                <path class="st1" d="M130.9,96c-1.2-2.6-0.1-5.7,2.5-6.9c2.6-1.2,5.7-0.1,6.9,2.5c1.2,2.6,0.1,5.7-2.5,6.9
	C135.3,99.7,132.2,98.6,130.9,96"/>
                <path class="st1" d="M120.1,102.2c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1
	C124.7,105.5,121.5,104.6,120.1,102.2"/>
                <path class="st1" d="M109.8,109.2c-1.6-2.4-1.1-5.6,1.3-7.2c2.4-1.6,5.6-1.1,7.2,1.3c1.6,2.4,1.1,5.6-1.3,7.3
	C114.7,112.2,111.4,111.6,109.8,109.2"/>
                <path class="st1" d="M100.1,117.2c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3
	C105.3,119.7,102,119.4,100.1,117.2"/>
                <path class="st1" d="M91.2,125.9c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4C96.6,128,93.3,128,91.2,125.9" />
                <path class="st1" d="M83.1,135.4c-2.2-1.8-2.5-5.1-0.6-7.3c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3
	C88.6,137,85.3,137.3,83.1,135.4"/>
                <path class="st1" d="M75.9,145.6c-2.4-1.6-2.9-4.9-1.3-7.2s4.9-2.9,7.2-1.3c2.4,1.6,2.9,4.9,1.3,7.3
	C81.5,146.7,78.2,147.2,75.9,145.6"/>
                <path class="st2" d="M69.5,156.3c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1
	C75.2,156.9,72,157.8,69.5,156.3"/>
                <path class="st2" d="M64.1,167.6c-2.6-1.2-3.7-4.3-2.5-6.9c1.2-2.6,4.3-3.7,6.9-2.5c2.6,1.2,3.7,4.3,2.5,6.9
	C69.8,167.7,66.7,168.8,64.1,167.6"/>
                <path class="st2" d="M56.4,191.3c-2.8-0.7-4.4-3.6-3.7-6.4c0.7-2.8,3.6-4.4,6.4-3.7c2.8,0.7,4.4,3.6,3.7,6.4
	C62.1,190.4,59.2,192.1,56.4,191.3"/>
                <path class="st2" d="M54.1,203.6c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6
	C59.7,202.2,57,204.1,54.1,203.6"/>
                <path class="st0" d="M52.9,216.1c-2.9-0.2-5-2.8-4.7-5.6c0.3-2.9,2.8-5,5.6-4.7c2.9,0.3,5,2.8,4.7,5.6
	C58.3,214.2,55.8,216.3,52.9,216.1"/>
                <path class="st0" d="M52.8,228.5c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2
	C58,226.2,55.7,228.5,52.8,228.5"/>
                <path class="st1" d="M53.8,241c-2.9,0.2-5.4-1.9-5.6-4.7c-0.2-2.9,1.9-5.4,4.7-5.6c2.9-0.2,5.4,1.9,5.6,4.7
	C58.8,238.2,56.7,240.8,53.8,241"/>
                <path class="st2" d="M55.9,253.3c-2.8,0.5-5.5-1.4-6-4.2c-0.5-2.8,1.4-5.5,4.2-6c2.8-0.5,5.5,1.4,6,4.2
	C60.6,250.1,58.7,252.8,55.9,253.3"/>
                <path class="st2" d="M59,265.4c-2.8,0.7-5.6-0.9-6.4-3.7c-0.7-2.8,0.9-5.6,3.7-6.4c2.8-0.7,5.6,0.9,6.4,3.7
	C63.4,261.8,61.8,264.7,59,265.4"/>
                <path class="st1" d="M63.2,277.2c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7c2.7-1,5.7,0.4,6.7,3.1
	C67.3,273.2,65.9,276.2,63.2,277.2"/>
                <path class="st1" d="M68.3,288.6c-2.6,1.2-5.7,0.1-6.9-2.5c-1.2-2.6-0.1-5.7,2.5-6.9c2.6-1.2,5.7-0.1,6.9,2.5
	C72.1,284.2,70.9,287.3,68.3,288.6"/>
                <path class="st1" d="M74.5,299.4c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9
	C77.8,294.8,77,298,74.5,299.4"/>
                <path class="st0" d="M81.6,309.7c-2.4,1.6-5.6,1.1-7.2-1.3c-1.6-2.4-1.1-5.6,1.3-7.2c2.4-1.6,5.6-1.1,7.2,1.3
	C84.5,304.8,83.9,308.1,81.6,309.7"/>
                <path class="st0" d="M89.5,319.4c-2.2,1.8-5.5,1.6-7.3-0.6c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6
	C92,314.2,91.7,317.5,89.5,319.4"/>
                <path class="st0" d="M98.2,328.3c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0C100.3,322.9,100.3,326.2,98.2,328.3" />
                <path class="st0" d="M107.7,336.4c-1.8,2.2-5.1,2.5-7.3,0.6c-2.2-1.8-2.5-5.1-0.6-7.3c1.8-2.2,5.1-2.5,7.3-0.6
	C109.3,330.9,109.6,334.2,107.7,336.4"/>
                <path class="st1" d="M117.9,343.6c-1.6,2.4-4.9,2.9-7.2,1.3c-2.4-1.6-2.9-4.9-1.3-7.2c1.6-2.4,4.9-2.9,7.2-1.3
	C119,338,119.6,341.3,117.9,343.6"/>
                <path class="st1" d="M128.7,350c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9
	C129.3,344.3,130.1,347.5,128.7,350"/>
                <path class="st2" d="M139.9,355.4c-1.2,2.6-4.3,3.7-6.9,2.5c-2.6-1.2-3.7-4.3-2.5-6.9c1.2-2.6,4.3-3.7,6.9-2.5
	C140,349.7,141.2,352.8,139.9,355.4"/>
                <path class="st1" d="M151.6,359.7c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7c1-2.7,4-4.1,6.7-3.1C151.2,354,152.6,357,151.6,359.7"
                />
                <path class="st1" d="M163.7,363.1c-0.7,2.8-3.6,4.4-6.4,3.7c-2.8-0.7-4.4-3.6-3.7-6.4c0.7-2.8,3.6-4.4,6.4-3.7
	C162.8,357.4,164.4,360.3,163.7,363.1"/>
                <path class="st1" d="M176,365.4c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2
	C174.6,359.8,176.5,362.5,176,365.4"/>
                <path class="st2" d="M188.4,366.6c-0.2,2.9-2.8,5-5.6,4.7c-2.9-0.3-5-2.8-4.7-5.6c0.2-2.9,2.8-5,5.6-4.7
	C186.5,361.2,188.6,363.7,188.4,366.6"/>
                <path class="st2" d="M200.9,366.7c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C198.5,361.5,200.9,363.8,200.9,366.7"/>
                <path class="st0" d="M213.3,365.7c0.2,2.9-1.9,5.4-4.7,5.6c-2.9,0.2-5.4-1.9-5.6-4.7c-0.2-2.9,1.9-5.4,4.7-5.6
	C210.6,360.7,213.1,362.8,213.3,365.7"/>
                <path class="st0" d="M225.6,363.6c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2c-0.5-2.8,1.4-5.5,4.2-6
	C222.4,358.9,225.1,360.8,225.6,363.6"/>
                <path class="st1" d="M237.7,360.5c0.7,2.8-0.9,5.6-3.7,6.4c-2.8,0.7-5.6-0.9-6.4-3.7c-0.7-2.8,0.9-5.6,3.7-6.4
	C234.1,356.1,237,357.7,237.7,360.5"/>
                <path class="st1" d="M249.5,356.3c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7
	C245.5,352.3,248.5,353.6,249.5,356.3"/>
                <path class="st1" d="M260.9,351.2c1.2,2.6,0.1,5.7-2.5,6.9s-5.7,0.1-6.9-2.5c-1.2-2.6-0.1-5.7,2.5-6.9
	C256.6,347.4,259.7,348.6,260.9,351.2"/>
                <path class="st0" d="M271.8,345c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1
	C267.1,341.7,270.3,342.5,271.8,345"/>
                <path class="st0" d="M282,337.9c1.6,2.4,1.1,5.6-1.3,7.2c-2.4,1.6-5.6,1.1-7.2-1.3c-1.6-2.4-1.1-5.6,1.3-7.2
	C277.2,335,280.4,335.6,282,337.9"/>
                <path class="st1" d="M291.7,330c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6c-1.8-2.2-1.6-5.5,0.6-7.3
	C286.6,327.5,289.8,327.8,291.7,330"/>
                <path class="st2" d="M300.6,321.3c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4C295.3,319.2,298.6,319.2,300.6,321.3" />
                <path class="st0" d="M308.7,311.8c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6c-2.2-1.8-2.5-5.1-0.6-7.3
	C303.2,310.2,306.5,309.9,308.7,311.8"/>
                <path class="st2" d="M316,301.6c2.4,1.6,2.9,4.9,1.3,7.2c-1.6,2.4-4.9,2.9-7.2,1.3c-2.4-1.6-2.9-4.9-1.3-7.2
	C310.4,300.5,313.6,299.9,316,301.6"/>
                <path class="st2" d="M322.3,290.8c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1
	C316.6,290.2,319.8,289.4,322.3,290.8"/>
                <path class="st0" d="M318.6,379.3c2.6,1.2,3.7,4.3,2.5,6.9c-1.2,2.6-4.3,3.7-6.9,2.5c-2.6-1.2-3.7-4.3-2.5-6.9
	C312.9,379.2,316,378.1,318.6,379.3"/>
                <path class="st1" d="M295.5,377.1c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7
	C289.8,377.5,292.8,376.1,295.5,377.1"/>
                <path class="st2" d="M335.4,255.8c2.8,0.7,4.4,3.6,3.7,6.4c-0.7,2.8-3.6,4.4-6.4,3.7c-2.8-0.7-4.4-3.6-3.7-6.4
	C329.8,256.7,332.6,255.1,335.4,255.8"/>
                <path class="st0" d="M337.7,243.5c2.8,0.5,4.7,3.2,4.2,6c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6
	C332.2,244.9,334.9,243,337.7,243.5"/>
                <path class="st0" d="M338.9,231.1c2.9,0.2,5,2.8,4.7,5.6c-0.2,2.9-2.8,5-5.6,4.7c-2.9-0.2-5-2.8-4.7-5.6
	C333.5,233,336,230.9,338.9,231.1"/>
                <path class="st1" d="M339,218.6c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2
	C333.8,221,336.1,218.6,339,218.6"/>
                <path class="st2" d="M338,206.2c2.9-0.2,5.4,1.9,5.6,4.7c0.2,2.9-1.9,5.4-4.7,5.6c-2.9,0.2-5.4-1.9-5.6-4.7
	C333,208.9,335.2,206.4,338,206.2"/>
                <path class="st2" d="M336,193.9c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2
	C331.2,197.1,333.1,194.4,336,193.9"/>
                <path class="st2" d="M332.8,181.8c2.8-0.7,5.6,0.9,6.4,3.7c0.7,2.8-0.9,5.6-3.7,6.4c-2.8,0.7-5.6-0.9-6.4-3.7
	C328.4,185.4,330.1,182.5,332.8,181.8"/>
                <path class="st1" d="M328.7,170c2.7-1,5.7,0.4,6.7,3.1c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1C324.6,174,326,171,328.7,170"
                />
                <path class="st1" d="M323.5,158.6c2.6-1.2,5.7-0.1,6.9,2.5c1.2,2.6,0.1,5.7-2.5,6.9c-2.6,1.2-5.7,0.1-6.9-2.5
	C319.8,162.9,320.9,159.8,323.5,158.6"/>
                <path class="st1" d="M317.3,147.7c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1s-5.7,0.6-7.1-1.9
	C314,152.4,314.9,149.2,317.3,147.7"/>
                <path class="st0" d="M310.3,137.5c2.4-1.6,5.6-1.1,7.2,1.3c1.6,2.4,1.1,5.6-1.3,7.2c-2.4,1.6-5.6,1.1-7.3-1.3
	C307.4,142.3,307.9,139.1,310.3,137.5"/>
                <path class="st0" d="M302.3,127.8c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6
	C299.8,132.9,300.1,129.7,302.3,127.8"/>
                <path class="st2" d="M293.6,118.9c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0C291.6,124.2,291.6,120.9,293.6,118.9" />
                <path class="st0" d="M284.1,110.8c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6
	C282.5,116.3,282.2,113,284.1,110.8"/>
                <path class="st0" d="M273.9,103.5c1.6-2.4,4.9-2.9,7.2-1.3c2.4,1.6,2.9,4.9,1.3,7.2c-1.6,2.4-4.9,2.9-7.3,1.3
	C272.8,109.1,272.3,105.9,273.9,103.5"/>
                <path class="st1" d="M263.2,97.2c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9
	C262.6,102.9,261.7,99.7,263.2,97.2"/>
                <path class="st2" d="M251.9,91.8c1.2-2.6,4.3-3.7,6.9-2.5c2.6,1.2,3.7,4.3,2.5,6.9c-1.2,2.6-4.3,3.7-6.9,2.5
	C251.8,97.5,250.7,94.4,251.9,91.8"/>
                <path class="st0" d="M240.2,87.4c1-2.7,4-4.1,6.7-3.1c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1C240.6,93.1,239.2,90.1,240.2,87.4"
                />
                <path class="st0" d="M228.2,84.1c0.7-2.8,3.6-4.4,6.4-3.7c2.8,0.7,4.4,3.6,3.7,6.4c-0.7,2.8-3.6,4.4-6.4,3.7
	C229.1,89.7,227.4,86.9,228.2,84.1"/>
                <path class="st1" d="M215.9,81.8c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6c-0.5,2.8-3.2,4.7-6,4.2
	C217.3,87.3,215.4,84.6,215.9,81.8"/>
                <path class="st2" d="M203.4,80.6c0.2-2.9,2.8-5,5.6-4.7c2.9,0.2,5,2.8,4.7,5.6c-0.3,2.9-2.8,5-5.6,4.7
	C205.3,86,203.2,83.5,203.4,80.6"/>
                <path class="st0" d="M191,67.6c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,72.8,191,70.5,191,67.6" />
                <path class="st0" d="M178.7,68.5c-0.2-2.9,1.9-5.4,4.8-5.6c2.9-0.2,5.4,1.9,5.6,4.8c0.2,2.9-1.9,5.4-4.8,5.6
	C181.4,73.5,178.9,71.3,178.7,68.5"/>
                <path class="st0" d="M166.6,70.3c-0.4-2.8,1.5-5.5,4.3-6c2.8-0.5,5.5,1.5,6,4.3c0.5,2.8-1.5,5.5-4.3,6
	C169.7,75.1,167,73.1,166.6,70.3"/>
                <path class="st2" d="M154.6,73.1c-0.7-2.8,1.1-5.6,3.8-6.3c2.8-0.7,5.6,1.1,6.3,3.8c0.7,2.8-1.1,5.6-3.8,6.3
	C158.1,77.6,155.3,75.9,154.6,73.1"/>
                <path class="st2" d="M142.9,76.8c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6
	C146.7,81,143.8,79.5,142.9,76.8"/>
                <path class="st0" d="M131.5,81.4c-1.1-2.7,0.2-5.7,2.8-6.8c2.7-1.1,5.7,0.2,6.8,2.8c1.1,2.7-0.2,5.7-2.8,6.8
	C135.7,85.3,132.6,84,131.5,81.4"/>
                <path class="st0" d="M120.6,86.9c-1.3-2.6-0.3-5.7,2.3-7c2.6-1.3,5.7-0.3,7,2.3c1.3,2.6,0.3,5.7-2.3,7
	C125,90.5,121.9,89.4,120.6,86.9"/>
                <path class="st2" d="M110,93.2c-1.5-2.5-0.7-5.7,1.7-7.2c2.5-1.5,5.7-0.7,7.2,1.7c1.5,2.4,0.7,5.7-1.7,7.2
	C114.7,96.4,111.5,95.7,110,93.2"/>
                <path class="st2" d="M73.3,89.2c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3
	C78.3,92,75,91.5,73.3,89.2"/>
                <path class="st1" d="M90.7,108.3c-1.9-2.2-1.6-5.5,0.6-7.3c2.2-1.9,5.5-1.6,7.3,0.6c1.9,2.2,1.6,5.5-0.6,7.3
	C95.8,110.7,92.5,110.4,90.7,108.3"/>
                <path class="st1" d="M81.9,116.9c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4C87.2,118.9,83.9,118.9,81.9,116.9" />
                <path class="st2" d="M73.9,126.1c-2.2-1.9-2.4-5.2-0.6-7.3c1.9-2.2,5.2-2.4,7.3-0.6c2.2,1.9,2.4,5.2,0.6,7.3
	C79.3,127.8,76.1,128,73.9,126.1"/>
                <path class="st2" d="M66.6,136c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3
	C72.2,137.2,68.9,137.7,66.6,136"/>
                <path class="st0" d="M60.1,146.4c-2.5-1.5-3.2-4.7-1.7-7.2c1.5-2.5,4.7-3.2,7.2-1.7c2.5,1.5,3.2,4.7,1.7,7.2
	C65.7,147.2,62.5,147.9,60.1,146.4"/>
                <path class="st0" d="M54.4,157.3c-2.6-1.3-3.6-4.4-2.3-7c1.3-2.6,4.4-3.6,7-2.3c2.6,1.3,3.6,4.4,2.3,7
	C60.1,157.6,57,158.6,54.4,157.3"/>
                <path class="st2" d="M49.6,168.6c-2.7-1.1-3.9-4.1-2.8-6.8c1.1-2.7,4.1-3.9,6.8-2.8c2.7,1.1,3.9,4.1,2.8,6.8
	C55.3,168.5,52.3,169.7,49.6,168.6"/>
                <path class="st1" d="M45.8,180.3c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6
	C51.4,179.7,48.5,181.2,45.8,180.3"/>
                <path class="st1" d="M42.8,192.2c-2.8-0.7-4.5-3.5-3.8-6.3c0.7-2.8,3.5-4.5,6.3-3.8c2.8,0.7,4.5,3.5,3.8,6.3
	C48.4,191.2,45.6,192.9,42.8,192.2"/>
                <path class="st0" d="M40.8,204.3c-2.8-0.4-4.8-3.1-4.3-6c0.4-2.8,3.1-4.8,6-4.3c2.8,0.4,4.8,3.1,4.3,6
	C46.3,202.8,43.6,204.8,40.8,204.3"/>
                <path class="st0" d="M39.7,216.5c-2.9-0.2-5-2.7-4.8-5.6c0.2-2.9,2.7-5,5.6-4.8c2.9,0.2,5,2.7,4.8,5.6
	C45.1,214.6,42.6,216.8,39.7,216.5"/>
                <path class="st0" d="M39.7,228.8c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2S42.5,228.8,39.7,228.8" />
                <path class="st1" d="M40.5,241.1c-2.9,0.2-5.4-1.9-5.6-4.8c-0.2-2.9,1.9-5.4,4.8-5.6c2.9-0.2,5.4,1.9,5.6,4.8
	C45.5,238.3,43.4,240.8,40.5,241.1"/>
                <path class="st0" d="M42.4,253.2c-2.8,0.4-5.5-1.5-6-4.3c-0.4-2.8,1.5-5.5,4.3-6c2.8-0.5,5.5,1.5,6,4.3
	C47.1,250.1,45.2,252.8,42.4,253.2"/>
                <path class="st1" d="M45.1,265.2c-2.8,0.7-5.6-1.1-6.3-3.8c-0.7-2.8,1.1-5.6,3.8-6.3c2.8-0.7,5.6,1.1,6.3,3.8
	C49.6,261.7,47.9,264.5,45.1,265.2"/>
                <path class="st1" d="M48.8,276.9c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3
	C53.1,273,51.6,276,48.8,276.9"/>
                <path class="st1" d="M53.4,288.2c-2.7,1.1-5.7-0.2-6.8-2.8c-1.1-2.7,0.2-5.7,2.8-6.8c2.7-1.1,5.7,0.2,6.8,2.8
	C57.4,284.1,56.1,287.1,53.4,288.2"/>
                <path class="st1" d="M58.9,299.2c-2.6,1.3-5.7,0.3-7-2.3c-1.3-2.6-0.3-5.7,2.3-7c2.6-1.3,5.7-0.3,7,2.3
	C62.5,294.8,61.5,297.9,58.9,299.2"/>
                <path class="st2" d="M65.3,309.7c-2.5,1.5-5.7,0.7-7.2-1.7c-1.5-2.5-0.7-5.7,1.7-7.2c2.5-1.5,5.7-0.7,7.2,1.7
	C68.5,305,67.7,308.2,65.3,309.7"/>
                <path class="st1" d="M72.4,319.7c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2
	C75.3,314.8,74.7,318,72.4,319.7"/>
                <path class="st0" d="M80.3,329.1c-2.2,1.9-5.5,1.6-7.3-0.6c-1.9-2.2-1.6-5.5,0.6-7.3c2.2-1.9,5.5-1.6,7.3,0.6
	C82.8,324,82.5,327.3,80.3,329.1"/>
                <path class="st2" d="M88.9,337.9c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4c2-2,5.3-2,7.4,0C91,332.5,91,335.8,88.9,337.9" />
                <path class="st0" d="M98.2,345.9c-1.9,2.2-5.2,2.4-7.3,0.6c-2.2-1.9-2.4-5.2-0.6-7.3c1.9-2.2,5.2-2.4,7.3-0.6
	C99.8,340.4,100.1,343.7,98.2,345.9"/>
                <path class="st0" d="M108.1,353.2c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3c1.7-2.3,4.9-2.8,7.3-1.2
	C109.3,347.6,109.8,350.9,108.1,353.2"/>
                <path class="st1" d="M118.5,359.7c-1.5,2.5-4.7,3.2-7.2,1.7c-2.4-1.5-3.2-4.7-1.7-7.2c1.5-2.5,4.7-3.2,7.2-1.7
	C119.2,354,120,357.2,118.5,359.7"/>
                <path class="st0" d="M129.4,365.3c-1.3,2.6-4.4,3.6-7,2.3c-2.6-1.3-3.6-4.4-2.3-7c1.3-2.6,4.4-3.6,7-2.3
	C129.7,359.7,130.7,362.8,129.4,365.3"/>
                <path class="st1" d="M140.7,370.1c-1.1,2.7-4.1,3.9-6.8,2.8c-2.7-1.1-3.9-4.1-2.8-6.8c1.1-2.7,4.1-3.9,6.8-2.8
	C140.5,364.4,141.8,367.5,140.7,370.1"/>
                <path class="st1" d="M152.3,374c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3
	C151.7,368.3,153.2,371.3,152.3,374"/>
                <path class="st1" d="M164.3,377c-0.7,2.8-3.5,4.5-6.3,3.8c-2.8-0.7-4.5-3.5-3.8-6.3c0.7-2.8,3.5-4.5,6.3-3.8
	C163.2,371.4,164.9,374.2,164.3,377"/>
                <path class="st1" d="M176.4,379c-0.4,2.8-3.1,4.8-6,4.3c-2.8-0.4-4.8-3.1-4.3-6c0.5-2.8,3.1-4.8,6-4.3
	C174.9,373.5,176.8,376.1,176.4,379"/>
                <path class="st2" d="M188.6,380c-0.2,2.9-2.7,5-5.6,4.8c-2.9-0.2-5-2.7-4.8-5.6c0.2-2.9,2.7-5,5.6-4.8
	C186.7,374.7,188.8,377.2,188.6,380"/>
                <path class="st2" d="M200.9,380.1c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C198.5,374.9,200.9,377.3,200.9,380.1"/>
                <path class="st0" d="M213.1,379.3c0.2,2.9-1.9,5.4-4.8,5.6c-2.9,0.2-5.4-1.9-5.6-4.8c-0.2-2.9,1.9-5.4,4.8-5.6
	C210.4,374.2,212.9,376.4,213.1,379.3"/>
                <path class="st0" d="M225.3,377.4c0.4,2.8-1.5,5.5-4.3,6c-2.8,0.5-5.5-1.5-6-4.3c-0.4-2.8,1.5-5.5,4.3-6
	C222.1,372.6,224.8,374.6,225.3,377.4"/>
                <path class="st2" d="M237.2,374.7c0.7,2.8-1.1,5.6-3.8,6.3c-2.8,0.7-5.6-1.1-6.3-3.8c-0.7-2.8,1.1-5.6,3.8-6.3
	C233.7,370.1,236.5,371.9,237.2,374.7"/>
                <path class="st2" d="M248.9,370.9c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6
	C245.1,366.7,248,368.2,248.9,370.9"/>
                <path class="st0" d="M260.3,366.3c1.1,2.7-0.2,5.7-2.8,6.8c-2.7,1.1-5.7-0.2-6.8-2.8c-1.1-2.7,0.2-5.7,2.8-6.8
	C256.2,362.4,259.2,363.7,260.3,366.3"/>
                <path class="st1" d="M271.3,360.8c1.3,2.6,0.3,5.7-2.3,7c-2.6,1.3-5.7,0.3-7-2.3c-1.3-2.6-0.3-5.7,2.3-7
	C266.8,357.3,270,358.3,271.3,360.8"/>
                <path class="st2" d="M281.8,354.5c1.5,2.5,0.7,5.7-1.7,7.2c-2.5,1.5-5.7,0.7-7.2-1.7c-1.5-2.5-0.7-5.7,1.7-7.2
	C277.1,351.3,280.3,352.1,281.8,354.5"/>
                <path class="st1" d="M291.8,347.4c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3
	C286.8,344.5,290.1,345,291.8,347.4"/>
                <path class="st1" d="M301.2,339.5c1.9,2.2,1.6,5.5-0.6,7.3c-2.2,1.9-5.5,1.6-7.3-0.6c-1.9-2.2-1.6-5.5,0.6-7.3
	C296,337,299.3,337.3,301.2,339.5"/>
                <path class="st1" d="M309.9,330.8c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0c-2-2-2-5.3,0-7.4C304.6,328.8,307.9,328.8,309.9,330.8" />
                <path class="st2" d="M318,321.6c2.2,1.9,2.4,5.2,0.6,7.3c-1.9,2.2-5.2,2.4-7.3,0.6c-2.2-1.9-2.4-5.2-0.6-7.3
	C312.5,320,315.8,319.7,318,321.6"/>
                <path class="st2" d="M325.3,311.7c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2c-2.3-1.7-2.8-4.9-1.2-7.3
	C319.7,310.5,322.9,310,325.3,311.7"/>
                <path class="st0" d="M331.7,301.3c2.5,1.5,3.2,4.7,1.7,7.2c-1.5,2.5-4.7,3.2-7.2,1.7c-2.5-1.5-3.2-4.7-1.7-7.2
	C326.1,300.5,329.3,299.8,331.7,301.3"/>
                <path class="st0" d="M337.4,290.4c2.6,1.3,3.6,4.4,2.3,7s-4.4,3.6-7,2.3c-2.6-1.3-3.6-4.4-2.3-7
	C331.7,290.1,334.8,289.1,337.4,290.4"/>
                <path class="st1" d="M342.2,279.1c2.7,1.1,3.9,4.1,2.8,6.8c-1.1,2.7-4.1,3.9-6.8,2.8c-2.7-1.1-3.9-4.1-2.8-6.8
	C336.5,279.2,339.5,278,342.2,279.1"/>
                <path class="st1" d="M346.1,267.4c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6
	C340.4,268,343.3,266.5,346.1,267.4"/>
                <path class="st2" d="M349,255.5c2.8,0.7,4.5,3.5,3.8,6.3c-0.7,2.8-3.5,4.5-6.3,3.8c-2.8-0.7-4.5-3.5-3.8-6.3
	C343.4,256.6,346.2,254.8,349,255.5"/>
                <path class="st0" d="M351,243.4c2.8,0.4,4.8,3.1,4.3,6c-0.4,2.8-3.1,4.8-6,4.3c-2.8-0.5-4.8-3.1-4.3-6
	C345.5,244.9,348.2,243,351,243.4"/>
                <path class="st0" d="M352.1,231.2c2.9,0.2,5,2.7,4.8,5.6c-0.2,2.9-2.7,5-5.6,4.8c-2.9-0.2-5-2.7-4.8-5.6
	C346.7,233.1,349.2,231,352.1,231.2"/>
                <path class="st1" d="M352.2,218.9c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2
	C347,221.2,349.3,218.9,352.2,218.9"/>
                <path class="st2" d="M351.3,206.7c2.9-0.2,5.4,1.9,5.6,4.8c0.2,2.9-1.9,5.4-4.8,5.6c-2.9,0.2-5.4-1.9-5.6-4.8
	C346.3,209.4,348.4,206.9,351.3,206.7"/>
                <path class="st2" d="M349.5,194.5c2.8-0.4,5.5,1.5,6,4.3c0.5,2.8-1.5,5.5-4.3,6c-2.8,0.4-5.5-1.5-6-4.3
	C344.7,197.6,346.6,195,349.5,194.5"/>
                <path class="st0" d="M346.7,182.6c2.8-0.7,5.6,1.1,6.3,3.8c0.7,2.8-1.1,5.6-3.8,6.3c-2.8,0.7-5.6-1.1-6.3-3.8
	C342.2,186,343.9,183.2,346.7,182.6"/>
                <path class="st2" d="M343,170.9c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3
	C338.8,174.7,340.3,171.7,343,170.9"/>
                <path class="st1" d="M338.4,159.5c2.7-1.1,5.7,0.2,6.8,2.8c1.1,2.7-0.2,5.7-2.8,6.8c-2.7,1.1-5.7-0.2-6.8-2.8
	C334.5,163.6,335.7,160.6,338.4,159.5"/>
                <path class="st1" d="M332.9,148.5c2.6-1.3,5.7-0.3,7,2.3c1.3,2.6,0.3,5.7-2.3,7c-2.6,1.3-5.7,0.3-7-2.3
	C329.3,152.9,330.3,149.8,332.9,148.5"/>
                <path class="st1" d="M326.6,138c2.5-1.5,5.7-0.7,7.2,1.7c1.5,2.5,0.7,5.7-1.7,7.2c-2.4,1.5-5.7,0.7-7.2-1.7
	C323.3,142.7,324.1,139.5,326.6,138"/>
                <path class="st1" d="M319.4,128c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2
	C316.6,132.9,317.1,129.7,319.4,128"/>
                <path class="st0" d="M311.5,118.6c2.2-1.9,5.5-1.6,7.3,0.6c1.9,2.2,1.6,5.5-0.6,7.3c-2.2,1.9-5.5,1.6-7.3-0.6
	C309.1,123.8,309.3,120.5,311.5,118.6"/>
                <path class="st0" d="M302.9,109.9c2-2,5.3-2,7.4,0c2,2,2,5.3,0,7.4c-2,2-5.3,2-7.4,0C300.9,115.2,300.9,111.9,302.9,109.9" />
                <path class="st2" d="M293.6,101.8c1.9-2.2,5.2-2.4,7.3-0.6c2.2,1.9,2.4,5.2,0.6,7.3c-1.9,2.2-5.2,2.4-7.3,0.6
	C292,107.3,291.8,104,293.6,101.8"/>
                <path class="st2" d="M283.8,94.5c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2
	C282.6,100.1,282.1,96.9,283.8,94.5"/>
                <path class="st0" d="M273.3,88c1.5-2.5,4.7-3.2,7.2-1.7c2.5,1.5,3.2,4.7,1.7,7.2c-1.5,2.5-4.7,3.2-7.2,1.7
	C272.6,93.7,271.8,90.5,273.3,88"/>
                <path class="st2" d="M262.4,82.4c1.3-2.6,4.4-3.6,7-2.3c2.6,1.3,3.6,4.4,2.3,7c-1.3,2.6-4.4,3.6-7,2.3
	C262.2,88.1,261.1,84.9,262.4,82.4"/>
                <path class="st2" d="M251.1,77.6c1.1-2.7,4.1-3.9,6.8-2.8c2.7,1.1,3.9,4.1,2.8,6.8c-1.1,2.7-4.1,3.9-6.8,2.8
	C251.3,83.3,250,80.2,251.1,77.6"/>
                <path class="st2" d="M239.5,73.7c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3
	C240.1,79.4,238.6,76.4,239.5,73.7"/>
                <path class="st0" d="M227.6,70.8c0.7-2.8,3.5-4.5,6.3-3.8c2.8,0.7,4.5,3.5,3.8,6.3c-0.7,2.8-3.5,4.5-6.3,3.8
	C228.6,76.4,226.9,73.6,227.6,70.8"/>
                <path class="st1" d="M215.5,68.7c0.4-2.8,3.1-4.8,6-4.3c2.8,0.4,4.8,3.1,4.3,6c-0.5,2.8-3.1,4.8-6,4.3
	C216.9,74.3,215,71.6,215.5,68.7"/>
                <path class="st1" d="M203.2,67.7c0.2-2.9,2.7-5,5.6-4.8c2.9,0.2,5,2.7,4.8,5.6c-0.2,2.9-2.7,5-5.6,4.8
	C205.1,73.1,203,70.6,203.2,67.7"/>
                <path class="st1" d="M191,54.7c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C193.3,59.9,191,57.6,191,54.7" />
                <path class="st2" d="M224.5,13.4c-0.2-2.9,2-5.4,4.8-5.6c2.9-0.2,5.4,2,5.6,4.8c0.2,2.9-2,5.4-4.8,5.6
	C227.2,18.5,224.7,16.3,224.5,13.4"/>
                <path class="st2" d="M167.5,57c-0.4-2.8,1.6-5.5,4.4-5.9c2.8-0.4,5.5,1.6,5.9,4.4s-1.6,5.5-4.4,5.9C170.5,61.9,167.9,59.9,167.5,57"
                />
                <path class="st2" d="M155.9,59.4c-0.6-2.8,1.2-5.6,4-6.2c2.8-0.6,5.6,1.2,6.2,4c0.6,2.8-1.2,5.6-4,6.2
	C159.3,64,156.5,62.2,155.9,59.4"/>
                <path class="st0" d="M144.6,62.6c-0.8-2.8,0.8-5.6,3.6-6.4c2.8-0.8,5.6,0.8,6.4,3.6c0.8,2.8-0.8,5.6-3.6,6.4
	C148.3,67,145.4,65.4,144.6,62.6"/>
                <path class="st2" d="M133.5,66.6c-1-2.7,0.4-5.7,3.1-6.7c2.7-1,5.7,0.4,6.7,3.1c1,2.7-0.4,5.7-3.1,6.7
	C137.5,70.7,134.5,69.3,133.5,66.6"/>
                <path class="st1" d="M122.7,71.3c-1.2-2.6,0-5.7,2.6-6.9c2.6-1.2,5.7,0,6.9,2.6c1.2,2.6,0,5.7-2.6,6.9
	C126.9,75.1,123.9,73.9,122.7,71.3"/>
                <path class="st1" d="M112.2,76.8c-1.3-2.5-0.4-5.7,2.2-7c2.5-1.4,5.7-0.4,7,2.2c1.3,2.5,0.4,5.7-2.2,7
	C116.7,80.3,113.6,79.3,112.2,76.8"/>
                <path class="st2" d="M102.2,83c-1.5-2.4-0.8-5.6,1.7-7.2c2.4-1.5,5.6-0.8,7.2,1.7c1.5,2.4,0.8,5.6-1.7,7.2
	C106.9,86.2,103.7,85.4,102.2,83"/>
                <path class="st1" d="M66.8,61.8c-1.7-2.3-1.2-5.6,1.2-7.3c2.3-1.7,5.6-1.2,7.3,1.2c1.7,2.3,1.2,5.6-1.2,7.3
	C71.7,64.6,68.5,64.1,66.8,61.8"/>
                <path class="st0" d="M52,89.5c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3C57.1,92,53.9,91.7,52,89.5
	"/>
                <path class="st0" d="M20.8,60.9c-2-2.1-1.9-5.4,0.1-7.4c2.1-2,5.4-1.9,7.4,0.1c2,2.1,1.9,5.4-0.1,7.4C26.1,63,22.8,63,20.8,60.9" />
                <path class="st0" d="M67.1,114.3c-2.1-1.9-2.3-5.2-0.4-7.4c1.9-2.1,5.2-2.3,7.4-0.4c2.1,1.9,2.3,5.2,0.4,7.4
	C72.5,116,69.2,116.2,67.1,114.3"/>
                <path class="st2" d="M59.8,123.5c-2.3-1.8-2.7-5-0.9-7.3c1.8-2.3,5-2.7,7.3-0.9c2.3,1.8,2.7,5,0.9,7.3
	C65.3,124.9,62,125.3,59.8,123.5"/>
                <path class="st2" d="M53.1,133.3c-2.4-1.6-3-4.8-1.4-7.2c1.6-2.4,4.8-3,7.2-1.4c2.4,1.6,3,4.8,1.4,7.2
	C58.7,134.2,55.5,134.9,53.1,133.3"/>
                <path class="st0" d="M47.2,143.4c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1
	C52.8,144,49.7,144.9,47.2,143.4"/>
                <path class="st2" d="M41.9,154c-2.6-1.3-3.7-4.4-2.4-7c1.3-2.6,4.4-3.7,7-2.4c2.6,1.3,3.7,4.4,2.4,7C47.6,154.2,44.5,155.3,41.9,154
	"/>
                <path class="st2" d="M37.5,164.9c-2.7-1.1-4-4.1-2.9-6.8c1.1-2.7,4.1-4,6.8-2.9c2.7,1.1,4,4.1,2.9,6.8
	C43.2,164.7,40.1,166,37.5,164.9"/>
                <path class="st0" d="M33.7,176.1c-2.7-0.9-4.2-3.8-3.3-6.6c0.9-2.7,3.8-4.2,6.6-3.3c2.7,0.9,4.2,3.8,3.3,6.6
	C39.4,175.5,36.5,177,33.7,176.1"/>
                <path class="st1" d="M30.8,187.5c-2.8-0.7-4.5-3.5-3.8-6.3c0.7-2.8,3.5-4.5,6.3-3.8c2.8,0.7,4.5,3.5,3.8,6.3
	C36.4,186.5,33.6,188.2,30.8,187.5"/>
                <path class="st1" d="M28.7,199.1c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6
	C34.3,197.8,31.6,199.6,28.7,199.1"/>
                <path class="st0" d="M26.9,222.7c-2.9-0.1-5.1-2.5-5-5.4c0.1-2.9,2.5-5.1,5.4-5c2.9,0.1,5.1,2.5,5,5.4
	C32.2,220.5,29.8,222.8,26.9,222.7"/>
                <path class="st1" d="M27.3,234.4c-2.9,0.1-5.3-2.1-5.4-5c-0.1-2.9,2.1-5.3,5-5.4c2.9-0.1,5.3,2.1,5.4,5
	C32.4,231.9,30.2,234.3,27.3,234.4"/>
                <path class="st1" d="M28.5,246.2c-2.9,0.3-5.4-1.8-5.7-4.6c-0.3-2.9,1.8-5.4,4.6-5.7c2.9-0.3,5.4,1.8,5.7,4.6
	C33.4,243.3,31.3,245.9,28.5,246.2"/>
                <path class="st1" d="M15.1,214c-2.8,0.5-5.5-1.4-6-4.2c-0.5-2.8,1.4-5.5,4.2-6c2.8-0.5,5.5,1.4,6,4.2
	C19.8,210.8,17.9,213.5,15.1,214"/>
                <path class="st0" d="M36.8,280.5c-2.7,0.9-5.7-0.6-6.6-3.3c-0.9-2.7,0.6-5.7,3.3-6.6c2.7-0.9,5.7,0.6,6.6,3.3
	C41,276.7,39.6,279.6,36.8,280.5"/>
                <path class="st0" d="M46.3,302.1c-2.6,1.3-5.7,0.2-7-2.4c-1.3-2.6-0.2-5.7,2.4-7c2.6-1.3,5.7-0.2,7,2.4
	C49.9,297.7,48.9,300.8,46.3,302.1"/>
                <path class="st1" d="M25.1,299.5c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9
	C28.4,294.9,27.6,298.1,25.1,299.5"/>
                <path class="st2" d="M58.7,322.1c-2.4,1.6-5.6,1-7.2-1.4c-1.6-2.4-1-5.6,1.4-7.2c2.4-1.6,5.6-1,7.2,1.4
	C61.7,317.3,61,320.5,58.7,322.1"/>
                <path class="st2" d="M65.9,331.5c-2.3,1.8-5.5,1.4-7.3-0.9c-1.8-2.3-1.4-5.5,0.9-7.3c2.3-1.8,5.5-1.4,7.3,0.9
	C68.5,326.4,68.1,329.7,65.9,331.5"/>
                <path class="st1" d="M73.7,340.3c-2.1,1.9-5.4,1.8-7.4-0.4c-1.9-2.1-1.8-5.4,0.4-7.4c2.1-1.9,5.4-1.8,7.4,0.4
	C76,335.1,75.9,338.4,73.7,340.3"/>
                <path class="st2" d="M82.2,348.5c-2,2.1-5.3,2.1-7.4,0.1c-2.1-2-2.1-5.3-0.1-7.4c2-2.1,5.3-2.1,7.4-0.1
	C84.1,343.2,84.2,346.5,82.2,348.5"/>
                <path class="st0" d="M91.2,356.2c-1.8,2.2-5.1,2.5-7.3,0.6c-2.2-1.8-2.5-5.1-0.6-7.3c1.8-2.2,5.1-2.5,7.3-0.6
	C92.7,350.7,93,354,91.2,356.2"/>
                <path class="st1" d="M110.6,369.5c-1.5,2.4-4.7,3.2-7.2,1.7c-2.4-1.5-3.2-4.7-1.7-7.2c1.5-2.4,4.7-3.2,7.2-1.7
	C111.4,363.8,112.1,367,110.6,369.5"/>
                <path class="st0" d="M121,375c-1.4,2.5-4.5,3.5-7,2.2c-2.5-1.3-3.5-4.5-2.2-7c1.4-2.5,4.5-3.5,7-2.2
	C121.4,369.4,122.4,372.5,121,375"/>
                <path class="st0" d="M131.8,379.9c-1.2,2.6-4.2,3.8-6.9,2.6c-2.6-1.2-3.8-4.2-2.6-6.9c1.2-2.6,4.2-3.8,6.9-2.6
	C131.7,374.2,132.9,377.3,131.8,379.9"/>
                <path class="st1" d="M142.8,384c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7c1-2.7,4-4.1,6.7-3.1C142.4,378.3,143.8,381.3,142.8,384"
                />
                <path class="st2" d="M154.1,418.4c-0.8,2.8-3.7,4.4-6.4,3.6c-2.8-0.8-4.4-3.7-3.6-6.4c0.8-2.8,3.7-4.4,6.4-3.6
	C153.3,412.7,154.9,415.6,154.1,418.4"/>
                <path class="st0" d="M83.5,382.5c-0.6,2.8-3.4,4.6-6.2,4c-2.8-0.6-4.6-3.4-4-6.2c0.6-2.8,3.4-4.6,6.2-4
	C82.3,376.9,84.1,379.7,83.5,382.5"/>
                <path class="st1" d="M177.3,391.5c-0.4,2.8-3,4.8-5.9,4.4c-2.8-0.4-4.8-3-4.4-5.9c0.4-2.8,3-4.8,5.9-4.4S177.7,388.7,177.3,391.5" />
                <path class="st1" d="M189.1,392.4c-0.2,2.9-2.7,5-5.6,4.8c-2.9-0.2-5-2.7-4.8-5.6c0.2-2.9,2.7-5,5.6-4.8
	C187.1,387.1,189.3,389.6,189.1,392.4"/>
                <path class="st0" d="M200.9,392.5c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C198.5,387.3,200.9,389.6,200.9,392.5"/>
                <path class="st0" d="M212.6,391.7c0.2,2.9-2,5.4-4.8,5.6c-2.9,0.2-5.4-2-5.6-4.8c-0.2-2.9,2-5.4,4.8-5.6
	C210,386.7,212.4,388.9,212.6,391.7"/>
                <path class="st0" d="M224.3,390.2c0.4,2.8-1.6,5.5-4.4,5.9c-2.8,0.4-5.5-1.6-5.9-4.4c-0.4-2.8,1.6-5.5,4.4-5.9
	C221.3,385.3,223.9,387.3,224.3,390.2"/>
                <path class="st2" d="M235.9,387.8c0.6,2.8-1.2,5.6-4,6.2c-2.8,0.6-5.6-1.2-6.2-4c-0.6-2.8,1.2-5.6,4-6.2
	C232.5,383.2,235.3,385,235.9,387.8"/>
                <path class="st2" d="M247.2,384.6c0.8,2.8-0.8,5.6-3.6,6.4c-2.8,0.8-5.6-0.8-6.4-3.6c-0.8-2.8,0.8-5.6,3.6-6.4
	C243.6,380.2,246.4,381.8,247.2,384.6"/>
                <path class="st0" d="M258.3,380.6c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7
	C254.4,376.5,257.4,377.9,258.3,380.6"/>
                <path class="st2" d="M269.1,375.9c1.2,2.6,0,5.7-2.6,6.9c-2.6,1.2-5.7,0-6.9-2.6c-1.2-2.6,0-5.7,2.6-6.9
	C264.9,372.1,268,373.2,269.1,375.9"/>
                <path class="st2" d="M279.6,370.4c1.4,2.5,0.4,5.7-2.2,7c-2.5,1.4-5.7,0.4-7-2.2c-1.3-2.5-0.4-5.7,2.2-7
	C275.1,366.9,278.2,367.8,279.6,370.4"/>
                <path class="st1" d="M289.6,364.2c1.5,2.4,0.8,5.6-1.7,7.2c-2.4,1.5-5.6,0.8-7.2-1.7c-1.5-2.4-0.8-5.7,1.7-7.2
	C284.9,361,288.1,361.8,289.6,364.2"/>
                <path class="st1" d="M299.2,357.3c1.7,2.3,1.2,5.6-1.2,7.3c-2.3,1.7-5.6,1.2-7.3-1.2c-1.7-2.3-1.2-5.6,1.2-7.3
	C294.3,354.5,297.5,355,299.2,357.3"/>
                <path class="st0" d="M308.3,349.8c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6c-1.8-2.2-1.6-5.5,0.6-7.3
	C303.2,347.3,306.4,347.6,308.3,349.8"/>
                <path class="st1" d="M316.8,341.6c2,2.1,1.9,5.4-0.1,7.4c-2.1,2-5.4,1.9-7.4-0.1c-2-2.1-1.9-5.4,0.1-7.4
	C311.5,339.5,314.8,339.6,316.8,341.6"/>
                <path class="st1" d="M324.8,332.9c2.1,1.9,2.3,5.2,0.4,7.4c-1.9,2.1-5.2,2.3-7.4,0.4c-2.1-1.9-2.3-5.2-0.4-7.4
	C319.3,331.2,322.6,331,324.8,332.9"/>
                <path class="st2" d="M332.1,323.7c2.3,1.8,2.7,5,0.9,7.3c-1.8,2.3-5,2.7-7.3,0.9c-2.3-1.8-2.7-5-0.9-7.3
	C326.5,322.3,329.8,321.9,332.1,323.7"/>
                <path class="st1" d="M338.7,313.9c2.4,1.6,3,4.8,1.4,7.2c-1.6,2.4-4.8,3-7.2,1.4c-2.4-1.6-3-4.8-1.4-7.2
	C333.1,312.9,336.3,312.3,338.7,313.9"/>
                <path class="st1" d="M344.7,303.7c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1
	C339,303.2,342.2,302.3,344.7,303.7"/>
                <path class="st1" d="M349.9,293.2c2.6,1.3,3.7,4.4,2.4,7c-1.3,2.6-4.4,3.7-7,2.4c-2.6-1.3-3.7-4.4-2.4-7
	C344.2,293,347.3,291.9,349.9,293.2"/>
                <path class="st1" d="M354.4,282.3c2.7,1.1,4,4.1,2.9,6.8c-1.1,2.7-4.1,4-6.8,2.9c-2.7-1.1-4-4.1-2.9-6.8
	C348.7,282.5,351.7,281.2,354.4,282.3"/>
                <path class="st1" d="M358.1,271.1c2.7,0.9,4.2,3.8,3.3,6.6c-0.9,2.7-3.8,4.2-6.6,3.3c-2.7-0.9-4.2-3.8-3.3-6.6
	C352.4,271.7,355.3,270.2,358.1,271.1"/>
                <path class="st2" d="M361,259.6c2.8,0.7,4.5,3.5,3.8,6.3c-0.7,2.8-3.5,4.5-6.3,3.8c-2.8-0.7-4.5-3.5-3.8-6.3
	C355.4,260.6,358.2,258.9,361,259.6"/>
                <path class="st0" d="M363.1,248c2.8,0.5,4.7,3.2,4.2,6c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6
	C357.6,249.4,360.3,247.5,363.1,248"/>
                <path class="st0" d="M364.4,236.3c2.9,0.3,4.9,2.9,4.6,5.7c-0.3,2.9-2.9,4.9-5.7,4.6c-2.9-0.3-4.9-2.9-4.6-5.7
	C359,238.1,361.5,236,364.4,236.3"/>
                <path class="st0" d="M364.9,224.5c2.9,0.1,5.1,2.5,5,5.4c-0.1,2.9-2.5,5.1-5.4,5c-2.9-0.1-5.1-2.5-5-5.4
	C359.6,226.7,362,224.4,364.9,224.5"/>
                <path class="st1" d="M364.5,212.7c2.9-0.1,5.3,2.1,5.4,5c0.1,2.9-2.1,5.3-5,5.4c-2.9,0.1-5.3-2.1-5.4-5
	C359.4,215.2,361.7,212.8,364.5,212.7"/>
                <path class="st2" d="M363.4,201c2.9-0.3,5.4,1.8,5.7,4.6c0.3,2.9-1.8,5.4-4.6,5.7c-2.9,0.3-5.4-1.8-5.7-4.6
	C358.4,203.9,360.5,201.3,363.4,201"/>
                <path class="st2" d="M361.4,189.4c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2
	C356.7,192.6,358.6,189.9,361.4,189.4"/>
                <path class="st2" d="M358.6,177.9c2.8-0.7,5.6,1,6.3,3.8c0.7,2.8-1,5.6-3.8,6.3c-2.8,0.7-5.6-1-6.3-3.8
	C354.1,181.4,355.8,178.6,358.6,177.9"/>
                <path class="st0" d="M355,166.7c2.7-0.9,5.7,0.6,6.6,3.3c0.9,2.7-0.6,5.7-3.3,6.6c-2.7,0.9-5.7-0.6-6.6-3.3
	C350.8,170.5,352.3,167.6,355,166.7"/>
                <path class="st1" d="M364.5,151.7c2.7-1.1,5.7,0.2,6.8,2.9c1.1,2.7-0.2,5.7-2.9,6.8c-2.7,1.1-5.7-0.2-6.8-2.9
	C360.6,155.8,361.9,152.8,364.5,151.7"/>
                <path class="st1" d="M345.5,145.1c2.6-1.3,5.7-0.2,7,2.4c1.3,2.6,0.2,5.7-2.4,7c-2.6,1.3-5.7,0.2-7-2.4
	C341.9,149.5,343,146.3,345.5,145.1"/>
                <path class="st0" d="M368.2,114.7c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9
	C364.9,119.4,365.7,116.2,368.2,114.7"/>
                <path class="st1" d="M346.3,109.7c2.4-1.6,5.6-1,7.2,1.4c1.6,2.4,1,5.6-1.4,7.2c-2.4,1.6-5.6,1-7.2-1.4
	C343.3,114.5,343.9,111.3,346.3,109.7"/>
                <path class="st1" d="M326,115.7c2.3-1.8,5.5-1.4,7.3,0.9s1.4,5.5-0.9,7.3c-2.3,1.8-5.5,1.4-7.3-0.9
	C323.3,120.7,323.7,117.5,326,115.7"/>
                <path class="st1" d="M318.1,106.9c2.1-1.9,5.4-1.8,7.4,0.4c1.9,2.1,1.8,5.4-0.4,7.4c-2.1,1.9-5.4,1.8-7.4-0.4
	C315.8,112.1,316,108.8,318.1,106.9"/>
                <path class="st0" d="M309.7,98.6c2-2.1,5.3-2.1,7.4-0.1c2.1,2,2.1,5.3,0.1,7.4c-2,2.1-5.3,2.1-7.4,0.1
	C307.7,104,307.7,100.7,309.7,98.6"/>
                <path class="st0" d="M300.7,91c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6
	C299.1,96.5,298.8,93.2,300.7,91"/>
                <path class="st2" d="M291.2,84c1.7-2.3,4.9-2.8,7.3-1.2c2.3,1.7,2.8,4.9,1.2,7.3c-1.7,2.3-4.9,2.8-7.3,1.2
	C290,89.6,289.5,86.4,291.2,84"/>
                <path class="st0" d="M281.2,77.7c1.5-2.4,4.7-3.2,7.2-1.7c2.4,1.5,3.2,4.7,1.7,7.2c-1.5,2.4-4.7,3.2-7.2,1.7
	C280.4,83.4,279.7,80.2,281.2,77.7"/>
                <path class="st0" d="M270.8,72.1c1.3-2.5,4.5-3.5,7-2.2c2.5,1.3,3.5,4.5,2.2,7c-1.4,2.5-4.5,3.5-7,2.2
	C270.4,77.8,269.5,74.7,270.8,72.1"/>
                <path class="st2" d="M260.1,67.3c1.2-2.6,4.2-3.8,6.9-2.6c2.6,1.2,3.8,4.2,2.6,6.9c-1.2,2.6-4.2,3.8-6.9,2.6
	C260.1,73,258.9,69.9,260.1,67.3"/>
                <path class="st2" d="M249,63.2c1-2.7,4-4.1,6.7-3.1c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1C249.4,68.9,248,65.9,249,63.2" />
                <path class="st0" d="M237.7,59.9c0.8-2.8,3.7-4.4,6.4-3.6c2.8,0.8,4.4,3.7,3.6,6.4c-0.8,2.8-3.7,4.4-6.4,3.6
	C238.5,65.5,236.9,62.6,237.7,59.9"/>
                <path class="st2" d="M226.2,57.3c0.6-2.8,3.4-4.6,6.2-4c2.8,0.6,4.6,3.4,4,6.2c-0.6,2.8-3.4,4.6-6.2,4
	C227.4,62.9,225.6,60.2,226.2,57.3"/>
                <path class="st1" d="M202.7,54.8c0.2-2.9,2.7-5,5.6-4.8c2.9,0.2,5,2.7,4.8,5.6c-0.2,2.9-2.7,5-5.6,4.8
	C204.7,60.1,202.5,57.6,202.7,54.8"/>
                <path class="st0" d="M177.1,33c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2C179.4,38.2,177.1,35.9,177.1,33"
                />
                <path class="st0" d="M179.6,159.9c-0.5-2.8,1.4-5.5,4.2-6c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6
	C182.8,164.6,180.1,162.7,179.6,159.9"/>
                <path class="st0" d="M168.7,163.7c-1-2.7,0.4-5.7,3.1-6.7c2.7-1,5.7,0.4,6.7,3.1c1,2.7-0.4,5.7-3.1,6.7
	C172.7,167.8,169.7,166.4,168.7,163.7"/>
                <path class="st0" d="M158.6,169.4c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1
	C163.3,172.7,160.1,171.9,158.6,169.4"/>
                <path class="st1" d="M149.7,176.7c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3
	C154.9,179.2,151.6,178.9,149.7,176.7"/>
                <path class="st2" d="M142.2,185.5c-2.2-1.8-2.5-5.1-0.6-7.3c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3
	C147.7,187.1,144.4,187.4,142.2,185.5"/>
                <path class="st2" d="M136.4,195.5c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1
	C142,196.1,138.9,196.9,136.4,195.5"/>
                <path class="st0" d="M132.3,206.3c-2.7-1-4.1-4-3.1-6.7c1-2.7,4-4.1,6.7-3.1c2.7,1,4.1,4,3.1,6.7C138,205.9,135,207.3,132.3,206.3"
                />
                <path class="st2" d="M130.2,217.6c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6
	C135.8,216.3,133.1,218.1,130.2,217.6"/>
                <path class="st2" d="M130.1,229.2c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2S133,229.2,130.1,229.2" />
                <path class="st2" d="M132,240.6c-2.8,0.5-5.5-1.4-6-4.2c-0.5-2.8,1.4-5.5,4.2-6c2.8-0.5,5.5,1.4,6,4.2
	C136.8,237.4,134.9,240.1,132,240.6"/>
                <path class="st1" d="M135.9,251.5c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7c2.7-1,5.7,0.4,6.7,3.1
	C140,247.5,138.6,250.5,135.9,251.5"/>
                <path class="st1" d="M141.6,261.5c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1c2.5-1.4,5.7-0.6,7.1,1.9
	C144.9,256.9,144.1,260.1,141.6,261.5"/>
                <path class="st2" d="M148.9,270.4c-2.2,1.8-5.5,1.6-7.3-0.6c-1.8-2.2-1.6-5.5,0.6-7.3c2.2-1.8,5.5-1.6,7.3,0.6
	C151.4,265.3,151.1,268.6,148.9,270.4"/>
                <path class="st2" d="M157.7,277.9c-1.8,2.2-5.1,2.5-7.3,0.6c-2.2-1.8-2.5-5.1-0.6-7.3c1.8-2.2,5.1-2.5,7.3-0.6
	C159.3,272.4,159.6,275.7,157.7,277.9"/>
                <path class="st1" d="M167.7,283.8c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1c1.4-2.5,4.6-3.3,7.1-1.9
	C168.2,278.1,169.1,281.3,167.7,283.8"/>
                <path class="st0" d="M178.5,287.8c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7c1-2.7,4-4.1,6.7-3.1
	C178.1,282.1,179.5,285.1,178.5,287.8"/>
                <path class="st0" d="M189.8,289.9c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6c0.5-2.8,3.2-4.7,6-4.2
	C188.4,284.4,190.3,287.1,189.8,289.9"/>
                <path class="st2" d="M201.4,290c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
	C199,284.8,201.4,287.1,201.4,290"/>
                <path class="st0" d="M212.8,288.1c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2c-0.5-2.8,1.4-5.5,4.2-6
	C209.6,283.4,212.3,285.3,212.8,288.1"/>
                <path class="st1" d="M223.6,284.2c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1c-1-2.7,0.4-5.7,3.1-6.7
	C219.7,280.2,222.7,281.5,223.6,284.2"/>
                <path class="st1" d="M233.7,278.6c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9c-1.4-2.5-0.6-5.7,1.9-7.1
	C229.1,275.2,232.2,276.1,233.7,278.6"/>
                <path class="st1" d="M242.6,271.2c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6c-1.8-2.2-1.6-5.5,0.6-7.3
	C237.5,268.7,240.7,269,242.6,271.2"/>
                <path class="st0" d="M250.1,262.4c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6c-2.2-1.8-2.5-5.1-0.6-7.3
	C244.6,260.9,247.9,260.6,250.1,262.4"/>
                <path class="st0" d="M255.9,252.5c2.5,1.4,3.3,4.6,1.9,7.1c-1.4,2.5-4.6,3.3-7.1,1.9c-2.5-1.4-3.3-4.6-1.9-7.1
	C250.3,251.9,253.5,251.1,255.9,252.5"/>
                <path class="st1" d="M260,241.7c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1c-2.7-1-4.1-4-3.1-6.7C254.3,242.1,257.3,240.7,260,241.7"
                />
                <path class="st2" d="M262.1,230.3c2.8,0.5,4.7,3.2,4.2,6c-0.5,2.8-3.2,4.7-6,4.2c-2.8-0.5-4.7-3.2-4.2-6
	C256.6,231.7,259.3,229.8,262.1,230.3"/>
                <path class="st1" d="M262.2,218.8c2.9,0,5.2,2.3,5.2,5.2c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2C257,221.1,259.3,218.8,262.2,218.8
	"/>
                <path class="st2" d="M260.3,207.4c2.8-0.5,5.5,1.4,6,4.2c0.5,2.8-1.4,5.5-4.2,6c-2.8,0.5-5.5-1.4-6-4.2
	C255.6,210.6,257.5,207.9,260.3,207.4"/>
                <path class="st2" d="M256.4,196.5c2.7-1,5.7,0.4,6.7,3.1c1,2.7-0.4,5.7-3.1,6.7c-2.7,1-5.7-0.4-6.7-3.1
	C252.3,200.5,253.7,197.5,256.4,196.5"/>
                <path class="st0" d="M250.7,186.5c2.5-1.4,5.7-0.6,7.1,1.9c1.4,2.5,0.6,5.7-1.9,7.1c-2.5,1.4-5.7,0.6-7.1-1.9
	C247.4,191.1,248.3,187.9,250.7,186.5"/>
                <path class="st1" d="M243.4,177.5c2.2-1.8,5.5-1.6,7.3,0.6c1.8,2.2,1.6,5.5-0.6,7.3c-2.2,1.8-5.5,1.6-7.3-0.6
	C240.9,182.7,241.2,179.4,243.4,177.5"/>
                <path class="st1" d="M234.6,170.1c1.8-2.2,5.1-2.5,7.3-0.6c2.2,1.8,2.5,5.1,0.6,7.3c-1.8,2.2-5.1,2.5-7.3,0.6
	C233.1,175.5,232.8,172.3,234.6,170.1"/>
                <path class="st2" d="M224.7,164.2c1.4-2.5,4.6-3.3,7.1-1.9c2.5,1.4,3.3,4.6,1.9,7.1s-4.6,3.3-7.1,1.9
	C224.1,169.9,223.2,166.7,224.7,164.2"/>
                <path class="st2" d="M213.9,160.2c1-2.7,4-4.1,6.7-3.1c2.7,1,4.1,4,3.1,6.7c-1,2.7-4,4.1-6.7,3.1
	C214.3,165.8,212.9,162.9,213.9,160.2"/>
                <path class="st2" d="M202.5,158c0.5-2.8,3.2-4.7,6-4.2c2.8,0.5,4.7,3.2,4.2,6c-0.5,2.8-3.2,4.7-6,4.2
	C203.9,163.6,202,160.9,202.5,158"/>
              </svg>
            </div>
          </div>
          <div className='w-1/2 h-full p-8 max-[991px]:w-full'>
            <div className='right-section h-full flex flex-col justify-center max-[991px]:items-center'>
              <h1 className='heading-one heading-1 -text--text-color1 uppercase banner_heading'>
                Transforming <span className='-text--text-color2'>Brands</span><br /> Forging connections,<br /> Inspiring actions.
              </h1>
              <p className='-text--text-color1 text-[20px] font-DidactGothicRegular'>Welcome to Brand Street Integrated</p>
            </div>
          </div>
        </div>
      </section >
      <section className='client-section -bg--bg-color'>
        <Swiper
          slidesPerView={2.3}
          spaceBetween={10}
          // centeredSlides={true}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 30,
            },
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-1.jpg')} alt='client-1' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-2.jpg')} alt='client-2' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-3.jpg')} alt='client-3' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-4.jpg')} alt='client-4' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-5.jpg')} alt='client-5' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-6.jpg')} alt='client-6' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-7.jpg')} alt='client-7' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-8.jpg')} alt='client-8' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-9.jpg')} alt='client-9' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='client-box-img'>
              <img src={require('../../assets/images/clients/client-10.jpg')} alt='client-10' />
            </div>
          </SwiperSlide>


        </Swiper>
      </section>
      <section className='top p-l-r-1 -bg--bg-color1'>
        <div className='about-section flex items-center'>
          <div className='left-section w-1/2'>
            <div className='header-title'>
              <h4 className='sub-heading'>
                What we do
              </h4>
              <h2 className='heading-1'>
                CRAFTING IMMERSIVE<br /> BRAND EXPERIENCES.
              </h2>
            </div>
          </div>
          <div className='right-section w-1/2 pr-11 max-[1199px]:pr-0 pt-5'>
            <p className='paragraph'>
              We excel in the delicate art of decoding the consumer psyche - offering a comprehensive suite of services from meticulous planning to flawless execution.
            </p>
          </div>
        </div>
        <div className='counter-section top_1 bottom_1'>
          <div className='countup-section wow fadeInUp flex items-center justify-evenly max-[576px]:flex-wrap -bg--bg-color2 p-6 rounded-md'>

            <div className='count_box w-full max-[576px]:w-1/2 max-[576px]:mb-5 text-center'>
              <h5 className='text-[60px] max-[991px]:text-[35px] max-[576px]:text-[40px] mb-0 -text--text-color font-HelveticaNeueMediumExt font-bold leading-none'><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={8} />+</h5>
              <p className='text-[18px] uppercase -text--text-color font-HelveticaNeueMediumExt max-[576px]:text-[16px] max-[576px]:mt-3'>Locations</p>
            </div>
            <div className='count_box w-full max-[576px]:w-1/2 max-[576px]:mb-5 text-center'>
              <h5 className='text-[60px] max-[991px]:text-[35px] max-[576px]:text-[40px] mb-0 -text--text-color font-HelveticaNeueMediumExt font-bold leading-none'><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={9} />+</h5>
              <p className='text-[18px] uppercase -text--text-color font-HelveticaNeueMediumExt max-[576px]:text-[16px] max-[576px]:mt-3'>Years</p>
            </div>
            <div className='count_box w-full max-[576px]:w-1/2 max-[576px]:mb-5 text-center'>
              <h5 className='text-[60px] max-[991px]:text-[35px] max-[576px]:text-[40px] mb-0 -text--text-color font-HelveticaNeueMediumExt font-bold leading-none'><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={200} />+</h5>
              <p className='text-[18px] uppercase -text--text-color font-HelveticaNeueMediumExt max-[576px]:text-[16px] max-[576px]:mt-3'>Professional</p>
            </div>
            <div className='count_box w-full max-[576px]:w-1/2 max-[576px]:mb-5 text-center'>
              <h5 className='text-[60px] max-[991px]:text-[35px] max-[576px]:text-[40px] mb-0 -text--text-color font-HelveticaNeueMediumExt font-bold leading-none'><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={300} />+</h5>
              <p className='text-[18px] uppercase -text--text-color font-HelveticaNeueMediumExt max-[576px]:text-[16px] max-[576px]:mt-3'>Brands</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bottom top-0 -bg--bg-color1'>
        <div className='service-section bottom_1 p-l-r-1 flex items-center'>
          <div className='left-section w-1/2'>
            <div className='header-title'>
              <h4 className='sub-heading'>
                How we do it
              </h4>
              <h2 className='heading-1 uppercase'>
                Audience<br />
                engagement
              </h2>
            </div>
          </div>
          <div className='right-section w-1/2  pr-11 max-[1199px]:pr-0 pt-5'>
            <p className='paragraph'>
              With our expertise in consumer marketing, trade marketing, digital solutions, and beyond, we're committed to propelling your brand toward unparalleled success.

            </p>
          </div>
        </div>
        <div className='service-section-2'>
          <h4 className='heading-1 p-l-r-1'>SERVICES</h4>
          <div className='service-list my-5 -bg--bg-color'>
            <ul className='relative'>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p><span>01</span>Consumer Marketing</p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p><span>02</span>Trade Marketing</p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p><span>03</span>Rural Marketing</p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p>
                    <span>04</span>Digital Marketing
                  </p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p><span>05</span>OOH</p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'>
                  <p><span>06</span>Creative Services</p>
                </a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'><p><span>07</span>Merchandising</p></a>
              </li>
              <li>
                <a href='#' className='relative text-[35px] max-[991px]:text-[28px] max-[768px]:text-[22px] max-[576px]:text-[18px] -text--text-color1 font-HelveticaNeueMediumExt block'><p><span>08</span>Printing and POSM</p></a>
              </li>
            </ul>
          </div>
          <div className='more-section p-l-r-1'>
            <Link to="/services" className='more-button text-[20px] font-HelveticaNeueMediumExt flex items-center button_animation'>
              <img src={require('../../assets/images/long_right.png')} className='ml-2 w-5 mt-1 left_icon' />
              Explore Services
              <img src={require('../../assets/images/long_right.png')} className='ml-2 w-5 mt-1 right_icon' />
            </Link>
          </div>
        </div>
      </section>
      <section className='-bg--bg-color1 bottom_1 max-[768px]:p-5'>
        <div className='header-title text-center'>
          <h3 className='heading-1 mb-4'>PROJECTS</h3>
          <p className='paragraph w-1/3 max-[991px]:w-1/2 max-[768px]:w-full text-center m-auto'>We excel in the delicate art of decoding the consumer psyche - offering a comprehensive suite of services from meticulous planning to flawless execution.</p>
        </div>
        <div className='projects-section martop'>
          <Swiper
            slidesPerView={1.4}
            spaceBetween={10}
            centeredSlides={true}
            breakpoints={{
              640: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 2.2,
                spaceBetween: 30,
              },
            }}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/BCL.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Burger.jpg')} alt='Burger' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Crompton.jpg')} alt='Crompton' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Kenstar.jpg')} alt='Kenstar' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Lakme.jpg')} alt='Lakme' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Never.jpg')} alt='Never' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Shell.jpg')} alt='Shell' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Sunfiest.jpg')} alt='Sunfiest' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/TGP.jpg')} alt='TGP' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='product-img'>
                <img src={require('../../assets/images/projects/Ujala.jpg')} alt='Ujala' />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='view-all-work py-16'>
          <Link to='/work' className='w-fit px-4 py-3 rounded-md -bg--bg-color -text--text-color1 text-[13px] font-HelveticaNeueMediumExt btn--2 btn_2'><span class="btn__text">View All Work</span></Link>
        </div>
      </section>
      <section className='top_1 bottom_1 p-l-r-1 -bg--bg-color'>
        <div className='about-section flex items-center'>
          <div className='left-section w-1/2'>
            <div className='header-title'>
              <h4 className='sub-heading -text--text-color1'>
                About us
              </h4>
              <h3 className='heading-1 -text--text-color1'>
                WE DELIVER RESULTS,<br />
                PLAIN AND SIMPLE.
              </h3>
            </div>
          </div>

        </div>
        <div className='about-section-2 martop'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className='about-img'>
                <img src={require('../../assets/images/banner-image-1.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='about-img'>
                <img src={require('../../assets/images/banner-image-2.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='about-img'>
                <img src={require('../../assets/images/banner-image-3.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='about-img'>
                <img src={require('../../assets/images/banner-image-4.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='about-img'>
                <img src={require('../../assets/images/banner-image-5.jpg')} alt='BCL1' />
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
        <div className='more-section pt-6 flex justify-end'>
          <Link to={'/about'} className='more-button text-[20px] button_animation font-HelveticaNeueMediumExt flex items-center -text--text-color1'>

            <img src={require('../../assets/images/long_right_1.png')} className='ml-2 w-5 mt-1 left_icon' />
            Know More

            <img src={require('../../assets/images/long_right_1.png')} className='ml-2 w-5 mt-1 right_icon' />
          </Link>
        </div>
      </section>
      <section className='news-section top_1 bottom_1 -bg--bg-color1 p-l-r-1 '>
        <div className='heade-title flex items-center justify-end'>
          <div className='w-1/2'>
            <h3 className='heading-1 mb-6 max-[576px]:mb-3'>LATEST NEWS</h3>
          </div>
        </div>
        <div className="w-full flex items-center justify-between max-[991px]:flex-wrap">
          <div className='left-section w-1/2 pr-10 max-[991px]:w-full max-[991px]:pl-0 max-[991px]:pr-0'>
            <img src={News ? News[imgSet]?.img:require('../../assets/images/image 1.png')} alt='image 1' className='w-full' />
          </div>
          <div className='right-section w-1/2 pl-10 max-[991px]:w-full max-[991px]:pl-0 max-[991px]:mt-8'>
            <div className='right-news-section'>

              {
                News?.map((item, index) => {
                  return (
                    <li
                      onMouseEnter={() => handleMouseEnter(index)}
                      // onMouseLeave={handleMouseLeave}
                    >

                      <h5>{item?.title}</h5>
                      <p className='paragraph'>{item?.text}</p>
                    </li>
                  )
                })
              }


               </div>
          </div>
        </div>
      </section>
      <Client />
    </>

  )
}


import React, { useEffect } from 'react'
import Client from '../client/Client'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link, useNavigate } from 'react-router-dom';
export default function Work() {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })

    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>Work</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        Where Vision <br />Meets Execution
                    </h2>
                </div>
            </section>
            <section className='-bg--bg-color1 work_list_section top_1 bottom_1 p-l-r-1'>
                <div class="grid grid-cols-2 max-[576px]:grid-cols-1  gap-20 max-[1199px]:gap-10 work-list">
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/Berger.png')} alt='Berger' />
                        </div>
                        <h4>Berger</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/work-1.png')} alt='Crompton' />
                        </div>
                        <h4>Crompton</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/work-2.png')} alt='Berger' />
                        </div>
                        <h4>Dark Fantasy</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/work-3.png')} alt='Berger' />
                        </div>
                        <h4>Ken Star</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/work-4.png')} alt='Berger' />
                        </div>
                        <h4>Lakme</h4>
                    </div>
                    <div className='work-card'>
                        <div className='img-card'>
                            <img src={require('../../assets/images/work-5.png')} alt='Berger' />
                        </div>
                        <h4>Never</h4>
                    </div>
                </div>
                <div className='pagination-box top_1'>
                    <ul className='flex items-center'>
                        <li><button className='active -text--text-color3'>1</button></li>
                        <li><button className='-text--text-color3' onClick={()=>navigate('/work-2')}>2</button></li>
                    </ul>
                </div>
            </section>
            <Client />
        </>
    )
}
